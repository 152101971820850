import {Component, OnInit,ViewChild, ElementRef} from '@angular/core';
import { Store } from 'src/app/store';
import { FormArray, FormBuilder, FormGroup,FormControl } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { FormValidators } from '../../../../utils/form-validators';
import { OrderService } from '../../services/orders.service';
import { ApiResponse } from '../../../../models/api-response';
import { ToastrService } from '../../../../shared/services/toastr.service';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { DomSanitizer } from '@angular/platform-browser';
import { DisplayTypeInventory } from 'src/app/models/order-job-detail';
import { CrewOrder } from 'src/app/models/crew-order';
import { ResponseType } from '../listorder/orders.component';
import { User } from 'src/app/models/user';
import {BarcodeScanner,BarcodeScannerOptions} from '@ionic-native/barcode-scanner/ngx';
import { ActionSheetController, IonSlides} from '@ionic/angular';
import {AlertController} from '@ionic/angular'
import { Platform } from '@ionic/angular';
import Quagga from 'quagga';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-remove-ad',
  templateUrl: 'remove-ad.html',
  styleUrls: ['remove-ad.scss']
})
export class RemoveAdComponent implements  OnInit{
  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('filePreInput') filePreInput: ElementRef;

  fgRemoval: FormGroup;
  loggedInUserID = null;
  loader;
  isSubmitDisable: boolean = false;
  selectedOrderDisplayType : DisplayTypeInventory= null;
  selectedOrderData: CrewOrder= null;
  source: string = '';
  installedImages : string[] = [];
  isPlatformPWA: boolean = false;
  preinstalldataimage: any;
  postinstalldataimage: any;
  isDynamicEvenDistribution : boolean = false;
  showCameraForBarcode : boolean = false;
  barcodeInputValue: any;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private orderService: OrderService,
    public toastService: ToastrService,
    public geolocation: Geolocation,
    private camera: Camera,
    public domSanitizer: DomSanitizer,
    private store:Store,
    private barcodeScanner: BarcodeScanner,
    private route: ActivatedRoute,
    private alertCtrl : AlertController,
    public platform: Platform,
    public actionController: ActionSheetController

  ) { }

  public prePhotos: string[] = [];
  public postPhotos: string[] = [];

  fileAttr = 'Choose File';
  prefileAttr = 'Choose File';
  navigatorObject = window.navigator;
  booleanNavigatorObject;
  isEnvironmentPWA : boolean;

  checkPlatform(){
    if(this.platform.is('mobileweb') || this.platform.is('pwa')){
      this.isPlatformPWA = true
    }else{
      this.isPlatformPWA = false;
    }
    this.booleanNavigatorObject =  Boolean(this.navigatorObject)
    this.isEnvironmentPWA = environment.pwa;
    console.log(this.isPlatformPWA,this.platform.platforms(), 'Navigator Value :',this.booleanNavigatorObject);
  }

  uploadPreFileEvent(preimgFile: any){
    if(preimgFile.target.files && preimgFile.target.files[0]) {
      this.prefileAttr = '';
      Array.from(preimgFile.target.files).forEach((file: File) => {
        this.prefileAttr += file.name ;
      });
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          let imgBase64Path = e.target.result;
          console.log(imgBase64Path);
          this.preinstalldataimage = imgBase64Path.replace(/^data:image\/[a-z]+;base64,/,'');
          this.fgRemoval.get('image_base64').setValue(this.preinstalldataimage);
          this.prePhotos.push(this.fgRemoval.get('image_base64').value);
          this.prePhotos.reverse();
          console.log(this.prePhotos)
        };
      };
      reader.readAsDataURL(preimgFile.target.files[0]);
      
      // Reset if duplicate image uploaded again
      this.filePreInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  async selectPreImage(){
    const actionSheet = await this.actionController.create({
      header: "Select Image Source",
      buttons: [{
        text: 'Upload from Gallery',
        handler: () => {
          this.takePrePictureFromGallery();
        }
      },
      {
          text: 'Use Camera',
          handler: () => {
            this.takePrePicture();
          }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
    ]
    });
    await actionSheet.present();
  }

  takePrePicture() {
    const options: CameraOptions = {
      targetWidth: 400,
      targetHeight: 600,
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      cameraDirection: this.camera.Direction.BACK
    }

    this.camera.getPicture(options).then((imageData) => {
      let base64Image = imageData;
      this.fgRemoval.get('image_base64').setValue(base64Image);
      this.prePhotos.push(this.fgRemoval.get('image_base64').value);
      this.prePhotos.reverse();
     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
        console.log("Error in taking picture", err);
     });
  }

  takePrePictureFromGallery(){
    this.camera.getPicture({
      quality: 100,
      targetWidth: 900,
      targetHeight: 600,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG

     }).then((imageData) => {
  
      let base64Image = imageData;
      this.fgRemoval.get('image_base64').setValue(base64Image);
      this.prePhotos.push(this.fgRemoval.get('image_base64').value);
      this.prePhotos.reverse();  
     // this.submitDisable();
     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
       console.log("Error in taking picture", err);
     });
     
  }
  
  uploadPostFileEvent(imgFile: any){
    if(imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name ;
      });
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          let imgBase64Path = e.target.result;
          console.log(imgBase64Path);
          this.postinstalldataimage = imgBase64Path.replace(/^data:image\/[a-z]+;base64,/,'');
          this.fgRemoval.get('image_base64').setValue(this.postinstalldataimage);
          this.postPhotos.push(this.fgRemoval.get('image_base64').value);
          this.postPhotos.reverse();
          console.log(this.postPhotos)
        };
      };
      reader.readAsDataURL(imgFile.target.files[0]);
      
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  async selectAfterImage(){
    const actionSheet = await this.actionController.create({
      header: "Select Image Source",
      buttons: [{
        text: 'Upload from Gallery',
        handler: () => {
          this.takePostPictureFromGallery();
        }
      },
      {
          text: 'Use Camera',
          handler: () => {
            this.takePostPicture();
          }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
    ]
    });
    await actionSheet.present();
  }

  takePostPicture() {
    const options: CameraOptions = {
      targetWidth: 400,
      targetHeight: 600,
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      cameraDirection: this.camera.Direction.BACK
    }

    this.camera.getPicture(options).then((imageData) => {
      let base64Image = imageData;
      this.fgRemoval.get('image_base64').setValue(base64Image);
      this.postPhotos.push(this.fgRemoval.get('image_base64').value);
      this.postPhotos.reverse();
     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
        console.log("Error in taking picture", err);
     });
  }

  takePostPictureFromGallery(){
    this.camera.getPicture({
      quality: 100,
      targetWidth: 900,
      targetHeight: 600,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG
     }).then((imageData) => {
      let base64Image = imageData;
      this.fgRemoval.get('image_base64').setValue(base64Image);
      this.postPhotos.push(this.fgRemoval.get('image_base64').value);
      this.postPhotos.reverse();  
     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
       console.log("Error in taking picture", err);
     });
     console.log(this.postPhotos);   
  }

  async deletePrePhoto(index) {
    let confirm = await this.alertCtrl.create({
      message: 'Are you sure you want to delete this photo?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            console.log('Disagree clicked');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Agree clicked');
            this.prePhotos.splice(index, 1);
          }
        }
      ]
    });
    await confirm.present();   
   }

   async deletePostPhoto(index) {
    let confirm = await this.alertCtrl.create({
      message: 'Are you sure you want to delete this photo?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            console.log('Disagree clicked');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Agree clicked');
            this.postPhotos.splice(index, 1);
          }
        }
      ]
    });
    await confirm.present();   
   }

  @ViewChild('slides') slides: IonSlides;

  slideNext() {
    this.slides.slideNext();
  }
  
  slidePrev() {
    this.slides.slidePrev();
  }

  findUserLocation(){ 
    let options = {
      enableHighAccuracy: true,
      timeout: 25000
    };


    return this.geolocation.getCurrentPosition(options).then((position) => {
      this.fgRemoval.get('latitude').setValue(position.coords.latitude)
      this.fgRemoval.get('longitude').setValue(position.coords.longitude)
     }).catch((error) => {
       console.log('Error getting location', error);
     });
  }

  get assignedUnitNumbers() {
    if(this.selectedOrderDisplayType) {
      return this.selectedOrderDisplayType.unit_details;
    }
    return [];
  }

  onChangeUnitNumber()
  { 
   
    if(this.fgRemoval.get('inventory').value && this.fgRemoval.get('inventory').value.bar_code) {
      this.fgRemoval.get('bar_code').setValue(this.fgRemoval.get('inventory').value.bar_code || '');
      this.installedImages = this.fgRemoval.get('inventory').value.installed_image.split(',');
    }
   
  }

  ngOnInit() {
    this.checkPlatform();
    this.source = this.route.snapshot.paramMap.get('source');
    this.store.set('screenTitle', "Removal");

    this.store.select('selectedOrderData').subscribe((selectedOrderData:ResponseType) => {
      this.selectedOrderData = selectedOrderData.selectedOrder;
    })

    this.store.select('selectedOrderDisplayType').subscribe((selectedOrderDisplayType:DisplayTypeInventory) => {
      this.selectedOrderDisplayType = selectedOrderDisplayType;
     })

    if(this.selectedOrderDisplayType.inventory_group == 'Dynamic' && this.selectedOrderDisplayType.zone_id == null &&  this.selectedOrderDisplayType.garage_id == null){
      this.isDynamicEvenDistribution = true;
    }else{
     this.isDynamicEvenDistribution = false;
    }

     this.store.select('userInfo').subscribe((userData:User) => {
      this.loggedInUserID = userData.user_id;
     })
     
    const { required } = FormValidators;
    this.fgRemoval = this.fb.group({
      order_id: [this.selectedOrderData?this.selectedOrderData.order_id:'', [required]],
      inventory_group: [this.selectedOrderDisplayType?this.selectedOrderDisplayType.inventory_group:'', [required]],
      post_date: [this.selectedOrderData?this.selectedOrderData.inv_post_date:'', [required]],
      expiry_date: [this.selectedOrderData?this.selectedOrderData.inv_expiry_date:'',[required]],
      display_type: [this.selectedOrderDisplayType?this.selectedOrderDisplayType.display_type:'',[required]],
      inventory : [((this.selectedOrderData && this.selectedOrderData.bar_code) || (this.selectedOrderDisplayType))?this.selectedOrderDisplayType.unit_details[0]:null,[required]],
      user_id : [this.loggedInUserID],
      unit_quantity : [this.selectedOrderDisplayType?this.selectedOrderDisplayType.quantity:''],
      bar_code : [''],
      image_base64 : [''],
      latitude: [''],
      longitude: ['']
    });

    this.onChangeUnitNumber();
  }

  async onSubmit() {
    this.isSubmitDisable= true;
    await this.findUserLocation();
    let data = { ...this.fgRemoval.getRawValue() };
    // TODO Uncomment below code once this.photo will be integrated
    if(!data.bar_code && this.barcodeInputValue){
      data.bar_code = this.barcodeInputValue;
    }

    data.image_data = [];
    this.prePhotos.forEach(photo => {
        data.image_data.push({'image_base64':photo,'action_type':'Pre-Removal'});
    });

    this.postPhotos.forEach(photo => {
      data.image_data.push({'image_base64':photo,'action_type':'Post-Removal'});
  });
  
  let submissionData = data;
  this.store.set('submittedOrderDetails', submissionData || null); 
  console.log(this.barcodeInputValue)
  console.log(data) 

    this.orderService.saveAdRemoval(data).subscribe(res => {
        this.isSubmitDisable= false;
        this.handleAlerts(res)
      }); 
  }

  scanBarCode() {
    const options: BarcodeScannerOptions = {
      torchOn: true,
      showTorchButton: true
    }
      this.barcodeScanner.scan(options).then(barcodeData => {
        this.fgRemoval.get('bar_code').setValue(barcodeData.text);
       }).catch(err => {
           console.log('Error in bar code scan', err);
       });
  }

  scan(){
    let canvas = document.getElementById('canvasArea');
    canvas.style.display = "none";
 
    this.showCameraForBarcode = true; 
     Quagga.init({
       inputStream: {
         constraints: {
           facingMode: 'environment' // restrict camera type
         },
         area: { // defines rectangle of the detection
           top: '40%',   // top offset
           right: '0%',  // right offset
           left: '0%',   // left offset
           bottom: '40%' // bottom offset
         },
       },
     },
     (err) => {
       if (err) {
        console.log("Error in bar code scan",err)
       } else {
         Quagga.start();
         Quagga.onDetected((res) => {
          this.fgRemoval.get('bar_code').setValue(res.codeResult.code);
          Quagga.stop();
          this.showCameraForBarcode = false;
         })
       }
     });
   }

  async handleAlerts(res: ApiResponse<any>) {
    if (res.isSuccess) {
      this.toastService.success('Inventory has been removed successfully');
      this.store.set('orderInstallRemoveID',res.data.order_install_remove_id);
      if(this.source==='my-work') {
        this.router.navigate(['submit-confirmation']);
      } else {
        this.router.navigate(['inventory']);
      }
      
    } else {
      this.toastService.error(res.errors || res.error);
    }
  }
}