import { Injectable } from "@angular/core";
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { StorageKey } from "../../models/storage-keys";
const { TOKEN_ID } = StorageKey;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  isLoggedIn = false;
  static authToken: any = null;
  static userId: string = null;

  constructor(
    private storage: NativeStorage
  ) {
  }

  saveToken(token: string) {
    this.storage.setItem(TOKEN_ID, token);
    AuthService.authToken = token;
    this.isLoggedIn = true;
  }

  deleteToken() {
    this.storage.remove(TOKEN_ID);
    this.isLoggedIn = false;
    AuthService.authToken = null;
  }

  getToken() {
    return this.storage.getItem(TOKEN_ID).then(
      data => {
        AuthService.authToken = data;
        if (AuthService.authToken != null) {
          this.isLoggedIn = true;
        } else {
          this.isLoggedIn = false;
        }
      },
      error => {
        AuthService.authToken = null;
        this.isLoggedIn = false;
      }
    );
  }
}
