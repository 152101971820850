import { Component, OnInit, ElementRef } from '@angular/core';
import { FormArray, FormBuilder, FormGroup,FormControl, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidators } from '../../../../utils/form-validators';
import { SurveyService } from '../../services/survey.service';
import { CommonService } from '../../../app/services/common.service';
import { ApiResponse } from '../../../../models/api-response';
import { Store } from 'src/app/store';
import { ToastrService } from '../../../../shared/services/toastr.service';
import { AuthorityContract } from 'src/app/models/authority-contract';
import { InventoryType } from 'src/app/models/inventory-type';
import { Market } from 'src/app/models/market';
import { RealDisplayType } from 'src/app/models/real-display-type';
import { InventoryDamageType } from 'src/app/models/inventory-damage-type';
import { AuthService } from 'src/app/auth/services/auth.service';
import { User } from 'src/app/models/user';
import { DomSanitizer } from '@angular/platform-browser';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Renderer2, ViewChild } from '@angular/core';
import { IonSlides} from '@ionic/angular';
import { environment } from '../../../../../environments/environment';



@Component({
  selector: 'app-advertisement',
  templateUrl: './advertisement.component.html',
  styleUrls: ['./advertisement.component.scss'],
})


export class AdvertisementComponent implements OnInit {
  @ViewChild('fileInput') fileInput: ElementRef;

  fgSurvey: FormGroup;
  contracts: AuthorityContract[]=[];
  inventoryTypes: InventoryType[] =[];
  loggedInUserID = null;
  unitDisplayTypes: RealDisplayType[] = [];
  segmentDisplayTypes;
  markets: Market[] = [];
  inventoryDamageTypes: InventoryDamageType[] = [];
  selectedDisplayType = '';
  isUnitBlank : boolean = false;
  isSubmitDisable: boolean = false;
  isEnvironmentPWA : boolean;
  fileAttr = 'Choose File';
  dataimage: any;

  constructor(private store: Store,
    private router: Router,
    private fb: FormBuilder,
    private surveyService: SurveyService,
    private commonService: CommonService,
    public toastService: ToastrService,
    private authService : AuthService,
    private camera: Camera,
    public domSanitizer: DomSanitizer,
) { }


  checkPlatform(){
    console.log(environment,environment.pwa);
    this.isEnvironmentPWA = environment.pwa;
  }

  segmentChanged(event){
     this.selectedDisplayType = event.target.value;
  }

  ngOnInit() {
    this.checkPlatform();
    this.store.set('screenTitle', "Advertisement Survey");
    const { required } = FormValidators;
    this.fgSurvey = this.fb.group({
      market_id: [null, [required]],
      contract_id: [null, [required]],
      inventory_type: [null, [required]],
      unit_number: ['', [required]],
      user_id: [null,[]],
      display_types: new FormArray([])
    });

    this.store.select('userInfo').subscribe((userData:User) => {
        this.loggedInUserID = userData.user_id;
        this.fgSurvey.get('user_id').setValue(this.loggedInUserID);
    })
  
    this.getAllMarkets();
    this.getAllInventoryDamageTypes();

  }

  onSubmit() {
    let data = { ...this.fgSurvey.getRawValue() };
    console.log(data);
    this.surveyService.saveAdvertisement(data).subscribe(res => {
        this.handleAlerts(res)
      });
  }

  radioButtonChange(event) {
    console.log('radioButtonChange', event.detail,this.fgSurvey.invalid);
    this.isUnitBlank = event.detail.value;
  }

  // isDamageReported(event){
  //   console.log('damageButtonChange', event.detail,this.fgSurvey.invalid);
  //   this.isAdDamaged = event.detail.checked;
  // }

  getDisplayTypesData() {
   this.isSubmitDisable= true;
   const postData = {inventory_type_id : this.fgSurvey.get('inventory_type').value.inventory_type_id, unit_number: this.fgSurvey.get('unit_number').value}
   //const postData = {inventory_type_id : 135, unit_number: 101012}
    this.surveyService
      .getDisplayTypes({},postData)
      .subscribe((res) => {
        this.isSubmitDisable= false;
        if(!res.isSuccess) {
          this.handleAlerts(res)
        } else {
          this.unitDisplayTypes= res.data;
          console.log(this.unitDisplayTypes)

          let segmentDisplayTypes = [];
          this.segmentDisplayTypes = [];
          this.unitDisplayTypes.filter(function(item) {
            var i = segmentDisplayTypes.findIndex(x => x.display_type == item.display_type);
            if(i <= -1){
              segmentDisplayTypes.push({display_type: item.display_type})
            }
            return null;
          });
          this.segmentDisplayTypes = segmentDisplayTypes;
          console.log(segmentDisplayTypes)
          const displayTypeFormArr = this.fgSurvey.get('display_types') as FormArray
          displayTypeFormArr.clear();
          if(this.unitDisplayTypes.length) {
            this.selectedDisplayType = this.unitDisplayTypes[0].display_type;
          }
          this.unitDisplayTypes.map(displayType => {
              let displayTypeFormArray = this.fgSurvey.get('display_types') as FormArray;
              displayTypeFormArray.push(
              new FormGroup({
                submit_survey: new FormControl(false),
                real_inventory_id : new FormControl(displayType.real_inventory_id),
                display_type: new FormControl(displayType.display_type),
                display_type_id: new FormControl(displayType.display_type_id),
                account_id: new FormControl(displayType.account_id),
                account_name: new FormControl(displayType.account_name),
                order_id: new FormControl(displayType.order_id),
                order_number: new FormControl(displayType.order_number),
                ad_type_id: new FormControl(displayType.ad_type_id),
                ad_type: new FormControl(displayType.ad_type),
                inv_post_date: new FormControl(displayType.inv_post_date),
                inv_expiry_date: new FormControl(displayType.inv_expiry_date),
                ltl_print_image: new FormControl(displayType.ltl_print_image),
                image_base64: new FormControl(''),
                notes: new FormControl(''),
                is_matched: new FormControl(false),
                is_blank: new FormControl(displayType.order_id ? false : true),
                any_damage: new FormControl(false)
              }))
        })
        }
      });   
  }

  getAllContracts() {
    if(this.fgSurvey.get('market_id').value) {
      this.commonService
        .getAllContracts({},{market_id: this.fgSurvey.get('market_id').value})
        .subscribe(({ data }) => {
          this.contracts= data;
        });
      }
  }

  getAllMarkets() {
    this.commonService
      .getAllMarkets({})
      .subscribe(({ data }) => {
        this.markets= data;
      });
  }

  getAllInventoryDamageTypes() {
    this.commonService
      .getAllInventoryDamageTypes({})
      .subscribe(({ data }) => {
        this.inventoryDamageTypes= data;
      });
  }

  getAssetTypeByContract() {
    if(this.fgSurvey.get('contract_id').value) {
      this.commonService
        .getAllAssetTypeByContract({},{contract_id:this.fgSurvey.get('contract_id').value})
        .subscribe(({ data }) => {
          this.inventoryTypes= data;
        });
    }
  }
  // Take Images on PWA
  uploadFileEvent(imgFile: any,displayType : FormGroup){
    if(imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name ;
      });
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          let imgBase64Path = e.target.result;
          console.log(imgBase64Path);
          this.dataimage = imgBase64Path.replace(/^data:image\/[a-z]+;base64,/,'');
          displayType.get('image_base64').setValue(this.dataimage);
          
          console.log(this.dataimage);
        };
      };
      reader.readAsDataURL(imgFile.target.files[0]);
      
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  takePicture(displayType :FormGroup) {
    const options: CameraOptions = {
      targetWidth: 400,
      targetHeight: 600,
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      cameraDirection: this.camera.Direction.BACK
    }

    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      let base64Image = imageData;//'data:image/jpeg;base64,' + imageData;
      displayType.get('image_base64').setValue(base64Image);
     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
        console.log("Error in taking picture", err);
     });
  }

  @ViewChild('slides') slides: IonSlides;

  slideNext() {
    this.slides.slideNext();
  }
  
  slidePrev() {
    this.slides.slidePrev();
  }  

  public newval: any;
  slideChanged(event) {
    this.newval = event.realIndex;
   }
   

  async handleAlerts(res: ApiResponse<any>) {
    if (res.isSuccess) {
      this.toastService.success('Advertisement Data Saved Successfully.');
      //this.fgSurvey.reset({user_id:this.loggedInUserID});
      this.fgSurvey.get('unit_number').setValue('');
      Object.keys(this.fgSurvey.controls).forEach(key => {
        this.fgSurvey.get(key).setErrors(null);
      });
      const allDisplayTypes =  this.fgSurvey.get('display_types') as FormArray;
      while (allDisplayTypes.length !== 0) {
        allDisplayTypes.removeAt(0)
      }
      this.unitDisplayTypes = [];
      //this.router.navigate(['dashboard']);
    } else {
      this.toastService.error(res.errors);
    }
  }

}
