import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/store';
import { CrewOrder } from 'src/app/models/crew-order';
import { OrderService } from '../../services/orders.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { FormValidators } from '../../../../utils/form-validators';
import { ApiResponse } from 'src/app/models/api-response';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { User } from 'src/app/models/user';


@Component({
  selector: 'app-reassign',
  templateUrl: './reassign.component.html',
  styleUrls: ['./reassign.component.scss'],
})
export class ReassignComponent implements OnInit {
  loggedInUserID: number = null;
  loggedInCrewVendorID: number = null;
  crewInstallOrders: CrewOrder[] = [];
  crewRemovalOrders: CrewOrder[] = [];
  crewUsers: User[] = [];
  fgReassign: FormGroup;

  constructor(
    private store: Store,
    private orderService: OrderService,
    private router: Router,
    public toastService:ToastrService,
    private fb: FormBuilder
    ) { }


  ionViewWillEnter() {
    this.store.set('screenTitle', "Re-Assign Order");
  }

  getCrewOrders() {
    this.orderService
      .getAllCrewOrders({},{crew_id : this.loggedInUserID})
      .subscribe(({ data }) => {
        this.crewInstallOrders = data.filter(order => order.order_type_category==='Install');
        this.crewRemovalOrders = data.filter(order => order.order_type_category==='Removal');
      });
  }

  getCrewList() {
    this.orderService
      .getAllCrews({},{vendor_id : this.loggedInCrewVendorID})
      .subscribe(({ data }) => {
        this.crewUsers = data;
      });
  }

  async onSubmit() {
    let data = { ...this.fgReassign.getRawValue() };
    this.orderService.orderReassign(data).subscribe(res => {
      this.handleAlerts(res);
      this.fgReassign.reset({action_type:'Install',user_id:this.loggedInUserID});
      this.getCrewOrders();
    });
  }

  async handleAlerts(res: ApiResponse<any>) {
    if(res.isSuccess) {
      this.toastService.success(res.message);
      this.router.navigate(['adfare']);  //added this to navigate back to the adfare screen after success
    } else {
      this.toastService.error(res.errors);
    }
  }

  get ordersList() {
    if(this.fgReassign.get('action_type').value==='Install') {
      return this.crewInstallOrders;
    } else {
      return this.crewRemovalOrders;
    }
  }


  ngOnInit() {

    this.store.set('screenTitle', "Installation");

    const { required } = FormValidators;

     this.store.select('userInfo').subscribe((userData:User) => {
      this.loggedInUserID = userData.user_id;
      this.loggedInCrewVendorID = userData.vendor_id;
     })

     this.fgReassign = this.fb.group({
      crew_id: [null, [required]],
      order_id: [null, [required]],
      action_type: ['Install', [required]],
      user_id : [this.loggedInUserID]
    });
    this.getCrewOrders();
    this.getCrewList();
  }
}
