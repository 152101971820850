import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';


const mockIsEnabled: boolean = environment.mock.enable;
const mockRoot: string = environment.mock.mockRoot;
const mockUrls: string[] = environment.mock.mockUrls;
const mustMockUrls: string[] = environment.mock.mustMockUrls;

@Injectable({ providedIn: 'root' })
export class RequestMockerService implements HttpInterceptor {

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const foundMockedUrl = this.urlHasToBeMocked(request.url);
    if (foundMockedUrl) {
      return this.generateMockedData(next, request, foundMockedUrl);
    } else {
      return next.handle(request);
    }
  }

  urlHasToBeMocked(apiUrl: string) {
    const mmu = mustMockUrls.find(mockUrl => mockUrl.trim() && apiUrl.includes(mockUrl));
    const mu = mockIsEnabled ? mockUrls.find(mockUrl => mockUrl.trim() && apiUrl.includes(mockUrl)) : null;
    return mmu || mu;
  }

  generateMockedData(next: HttpHandler, request: HttpRequest<any>, foundMockUrl: string): Observable<HttpEvent<any>> {
    const mockUrl = this.getMockUrl(request, foundMockUrl);
    return next.handle(request.clone({ url: mockUrl, method: 'GET' }))
      .pipe(map(event => event));
  }

  private getMockUrl(request: HttpRequest<any>, foundMockUrl: string) {
    const mockedUrl = `${mockRoot.trim()}/${foundMockUrl.trim()}/${request.method.toLowerCase()}.json`;
    return (foundMockUrl.trim() ? mockedUrl : request.url.trim()).replace('//', '/').trim();
  }
}
