import { Component,ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'src/app/store';
import { User } from 'src/app/models/user';
import { CrewOrder } from 'src/app/models/crew-order';
import { DisplayTypeInventory } from 'src/app/models/order-job-detail';
import { IonSlides} from '@ionic/angular';
import { DomSanitizer } from '@angular/platform-browser';

interface InventoryData{
  order_data: CrewOrder,
  display_type_data: DisplayTypeInventory
}

@Component({
  selector: 'app-expired-ad',
  templateUrl: './expired-ad.component.html',
  styleUrls: ['./expired-ad.component.scss'],
})
export class ExpiredAdComponent {

  loggedInUserID = null;
  searchInventoryData: InventoryData[] = [];

  constructor(private store: Store,
    private router: Router
  ) { }

  removeOrder(selectedInventoryData:InventoryData ) {
    const selectedOrderData = {orderType:'Remove', selectedOrder: selectedInventoryData.order_data };
    this.store.set('selectedOrderData', selectedOrderData|| null)
    this.store.set('selectedOrderDisplayType', selectedInventoryData.display_type_data || null)
    this.router.navigate(['remove-ad','check-inventory']);
  }
 
  ionViewWillEnter() {
    this.store.set('screenTitle', "Ad Details");
    this.store.select('userInfo').subscribe((userData:User) => {
        this.loggedInUserID = userData.user_id;
    });
    this.store.select('searchInventoryData').subscribe((searchInventoryData:{order_data:CrewOrder,display_type_data:DisplayTypeInventory}[]) => {
      this.searchInventoryData = searchInventoryData;
    })
  }

  @ViewChild('slides') slides: IonSlides;

  slideNext() {
    this.slides.slideNext();
  }
  
  slidePrev() {
    this.slides.slidePrev();
  }  
  
}
