import { BehaviorSubject, Observable } from "rxjs";
import { distinctUntilChanged, pluck } from "rxjs/operators";
import { State } from "./models/state";

const state: State = {
   userInfo:null,
   screenTitle:'Ad Fare',
  // table selections
  authorityContractData : [],
  inventoryTypeData: [],
  realUnitDisplayTypeData: [],
  marketData: [],
  inventoryDamageTypeData: [],
  searchInventoryData : [],

  // Selected Record
  selectedOrderData: null,
  selectedOrderDisplayType: null,
  successMsg: '',
  selectedWorkOrder: null,
  submittedOrderDetails: null,
  orderInstallRemoveID: null
};

export class Store {
  private subject = new BehaviorSubject<State>(state);
  private store = this.subject.asObservable().pipe(distinctUntilChanged());

  get value() {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(pluck(name));
  }

  set(name: string, _state: any) {
    this.subject.next({
      ...this.value,
      [name]: _state
    });
  }
}
