import { Component } from '@angular/core';
import {Store} from 'src/app/store';
import {Router} from '@angular/router';
import { User } from 'src/app/models/user';


@Component({
  selector: 'app-adsurvey',
  templateUrl: './adsurvey.component.html',
  styleUrls: ['./adsurvey.component.scss'],
})
export class AdsurveyComponent {

  loggedInUserData = null;
  
  constructor(private store: Store, public router: Router) {}

  workOrder(){
    this.router.navigate(['adfare'])
  }

  survey(){
    this.router.navigate(['advertisement']);
  }

  fieldService(){
    this.router.navigate(['field-service']);
  }

  
  ionViewWillEnter() {
    this.store.set('screenTitle',"Home Page");
    this.store.select('userInfo').subscribe((userData:User) => {
      this.loggedInUserData = userData;
    })
  }


}
