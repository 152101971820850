import { Inject, Injectable } from "@angular/core";
import { API_URL } from "../../../models/environment.token";
import { Store } from "../../../store";
import { HTTPService } from "../../../shared/services/http.service";
import { PagerQuery } from '../../../models/pager';
import { SurveyAdvertisement } from '../../../models/survey-advertisement';

import { ApiResponse } from '../../../models/api-response';
import { Observable, throwError } from 'rxjs';
import { catchError, retry, tap } from 'rxjs/operators';
import { RealDisplayType } from '../../../models/real-display-type';
import {generateFilterFields} from '../../../utils/helpers'

@Injectable({ providedIn: "root" })
export class SurveyService {
  constructor(
    private store: Store,
    private http: HTTPService,
    @Inject(API_URL) private api: string
  ) {}

  getDisplayTypes(query: PagerQuery,filters={}, isToBeExported = false) {
    return this.http
      .get<ApiResponse<RealDisplayType[]>>(
        this.api + 'survey-api/inventory-display-type?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          !isToBeExported && this.store.set('realUnitDisplayTypeData', res.data || []);
        }),
        catchError(err => {
          !isToBeExported && this.store.set('realUnitDisplayTypeData', []);
          return throwError(err);
        })
      );
  }

  saveAdvertisement(data: SurveyAdvertisement): Observable<any> {
    return this.http
      .post<ApiResponse<number[]>>(this.api + 'survey-api/inventory-display-type', data)
      .pipe(
        tap(res => {
          if (res.isSuccess) {
            return res.data;
          }
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
