import { Component, OnInit , ViewChild} from '@angular/core';
import { AlertController } from '@ionic/angular';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Store } from 'src/app/store';
import { User } from 'src/app/models/user';
import { WorkOrderService } from '../../services/work-order.service';
import { WorkOrder, WorkOrderDetail, WorkFlowEntity, ImageDataPayloadEntity } from 'src/app/models/work-order.types';
import { ApiResponse } from 'src/app/models/api-response';
import { ModalController} from '@ionic/angular';
import { UpdateConcernComponent } from './update-concern/update-concern.component';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-workorder-detail',
  templateUrl: './workorder-detail.component.html',
  styleUrls: ['./workorder-detail.component.scss'],
})
export class WorkorderDetailComponent {

  constructor(
    public toastService: ToastrService, 
    private alertCtrl: AlertController, 
    private store: Store,
    private camera: Camera,
    private workOrderService: WorkOrderService,
    private modalCtrl: ModalController
  ) { }

  public photos: ImageDataPayloadEntity[];
  loggedInUserID: number = null;
  selectedWorkOrder: WorkOrder = null;
  workOrderDetail: WorkOrderDetail = null;
  workOrderFlowSections: any = {};
  inprogressOrderDetail: WorkFlowEntity = null;
  remark: string = '';

  currentPosition;
  height;
  minimumThreshold;
  startPosition;

  workOrderDetailSubscriber: Subscription;
  workOrderSubscriber: Subscription;

 
  openSheet(){
    this.modalCtrl.create({
      component: UpdateConcernComponent,
      cssClass: 'modalCss'
      
    }).then( modelres => {
      modelres.present();
    })
  }


  openAccordion(selectedId) {
    for (const flow in this.workOrderFlowSections) {
      this.workOrderFlowSections[flow] = false;
    }
    this.workOrderFlowSections[selectedId] = true;
  }

  takePicture() {
    const options: CameraOptions = {
      targetWidth: 900,
      targetHeight: 600,
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG
    }
    this.camera.getPicture(options).then((imageData) => {
      let base64Image = imageData;
      this.photos.push({image_base64: base64Image, image_name: 'image_'+Date.now()});
      this.photos.reverse();

     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
        console.log("Error in taking picture", err);
     });

  }

  async deletePhoto(index) {
    let confirm = await this.alertCtrl.create({
      message: 'Are you sure you want to delete this photo?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            console.log('Disagree clicked');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Agree clicked');
            this.photos.splice(index, 1);
          }
        }
      ]
    });
    await confirm.present();   
   }

   getWorkOrderDetail() {
    if(this.selectedWorkOrder) {
      this.workOrderDetailSubscriber = this.workOrderService
        .getWorkOrderDetails({},{work_order_id : this.selectedWorkOrder.work_order_id}) 
        .subscribe(({ data }) => {
          this.workOrderDetail = data;
          if(data.work_flow) {
            data.work_flow.forEach(workFlow => {
              if(workFlow.workflow_status=='2') {
                this.inprogressOrderDetail = workFlow;
              }
              this.workOrderFlowSections[workFlow.work_order_service_type_task_id] = workFlow.workflow_status=='2'?true:false;
            })
          }
        });
      }
      
  }

  validateFields() {
    return this.remark && (this.photos.length>0 || this.inprogressOrderDetail.is_pic_mandatory=='0') ? false:true;
  }

  saveWorkFlowRemark() {
    this.workOrderService
      .saveWorkFlowRemark({
        work_order_id: this.selectedWorkOrder.work_order_id, 
        work_order_service_type_task_id: this.inprogressOrderDetail.work_order_service_type_task_id,
        remark: this.remark,
        image_data: this.photos
        }) 
      .subscribe((res) => {
        this.handleAlerts(res);
        this.remark ='';
        this.photos =[];
        this.getWorkOrderDetail();
      });
  }

  // updateConcern() {
  //   const docData = [];
  //   this.photos.forEach(photo => {
  //     docData.push({
  //         doc_base64: photo.image_base64,
  //         doc_name: photo.image_name
  //       })
  //   })
  //   this.workOrderService
  //     .updateConcern({
  //         work_order_id: this.selectedWorkOrder.work_order_id,
  //         wo_comment_type: 'Update Workorder',
  //         wo_comment: this.remark,
  //         doc_data: docData
  //       }) 
  //     .subscribe((res) => {
  //       this.handleAlerts(res);
  //       this.remark ='';
  //       this.photos =[];
  //       this.getWorkOrderDetail();
  //     });
  // }

  startWorkOrder() {
    this.workOrderService
      .startWorkOrder({work_order_id: this.selectedWorkOrder.work_order_id, vendor_id: this.selectedWorkOrder.vendor_id}) 
      .subscribe((res) => {
        this.handleAlerts(res);
        this.getWorkOrderDetail();
      });
  }

  async handleAlerts(res: ApiResponse<any>) {
    if(res.isSuccess) {
      this.toastService.success(res.message);
    } else {
      this.toastService.error(res.errors);
    }
  }

  ionViewWillEnter() {
    this.photos=[];
   
    this.store.set('screenTitle',["Work Order Details"]);
    this.store.select('userInfo').subscribe((userData:User) => {
    this.loggedInUserID = userData.user_id;
    })
    this.workOrderSubscriber = this.store.select('selectedWorkOrder').subscribe((workOrder:WorkOrder) => {
    this.selectedWorkOrder = workOrder;
    this.getWorkOrderDetail();
    })
  }

  ionViewWillLeave() {
    this.workOrderDetailSubscriber.unsubscribe();
    this.workOrderSubscriber.unsubscribe();
  }

}
