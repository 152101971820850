import { Inject, Injectable, ɵConsole } from "@angular/core";
import { Observable, throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ApiResponse } from "../../../models/api-response";
import { API_URL } from "../../../models/environment.token";
import { Store } from "../../../store";
import { HTTPService } from "./../../../shared/services/http.service";
import { AuthService } from "./../../../auth/services/auth.service";
import { Login } from "../../../models/user";
import { NativeStorage } from '@ionic-native/native-storage/ngx';

@Injectable({ providedIn: "root" })
export class UserService {
  constructor(
    private store: Store,
    private http: HTTPService,
    private authService: AuthService,
    @Inject(API_URL) private api: string,
    private storage: NativeStorage
  ) {}

  loginUser(data: Login): Observable<any> {
    return this.http.post<ApiResponse<any>>(this.api + "fsm-vendor-api/login", data).pipe(
      tap(res => {
        if (res.isSuccess) {
          // auth service logged in will set here
          this.authService.saveToken(res.data.token);
          this.store.set('userInfo', res.data.user || null)
          localStorage.setItem('userId',res.data.user.user_id)
        }
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  forgotPassword( data: {user_name:string}): Observable<any> {
    return this.http.post<ApiResponse<any>>(this.api + "fsm-vendor-forgot-password/user", data).pipe(
      tap(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }
}
