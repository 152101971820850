import { Component } from '@angular/core';
import { Store } from 'src/app/store';
import { Router } from '@angular/router';
import { OrderService } from '../../services/orders.service';
import {ActivatedRoute} from '@angular/router';
import { CrewOrder } from 'src/app/models/crew-order';
import { OrderJobDetail , DisplayTypeInventory} from 'src/app/models/order-job-detail';
import { ResponseType } from '../listorder/orders.component';
import { RealDisplayType } from 'src/app/models/real-display-type';
import { ModalController } from '@ionic/angular';
import { UploadInvoiceComponent } from './upload-invoice/upload-invoice.component';


@Component({
  selector: 'app-display-list',
  templateUrl: './display-list.component.html',
  styleUrls: ['./display-list.component.scss'],
})
export class DisplayListComponent {
  orderType: string = '';
  unitDisplayTypes: RealDisplayType[] = [];
  selectedOrder : CrewOrder=null;

  orderJobDetails : OrderJobDetail= null;
  constructor(
    private router: Router,
    private orderService: OrderService,
    private store:Store,
    private route: ActivatedRoute,
    private modalCtrl: ModalController
  ) {}

  openInvoiceSheet(){
    this.modalCtrl.create({
     component: UploadInvoiceComponent,
      cssClass: 'uploadInvoiceCss'
      
    }).then( modelres => {
      modelres.present();
    })
  }

  onClickDisplayType(orderDisplayType: DisplayTypeInventory){
      this.store.set('selectedOrderDisplayType', orderDisplayType || null)
      if(this.orderType === 'Install') 
        this.router.navigate(['install-ad'])
      else
        this.router.navigate(['remove-ad','my-work'])
  }  

  get jobDetails () {
    const jobDetails = [];
    if(this.orderJobDetails) {
      const keys = Object.keys(this.orderJobDetails.install_inventories);
      keys.map(key => {
        jobDetails.push(this.orderJobDetails.install_inventories[key])
      })
    }
   
    return jobDetails;
  }
  
  getOrderJobDetails() {
    if(this.selectedOrder) {
      if(this.orderType === 'Install') {
        this.orderService
          .getInstallOrderJobDetails({},{order_id: this.selectedOrder.order_id,inventory_group : this.selectedOrder.inventory_group
          })
          .subscribe(({ data }) => {
            this.orderJobDetails = data;
          });
        } else {
          this.orderService
          .getRemoveOrderJobDetails({},{order_id: this.selectedOrder.order_id,inventory_group : this.selectedOrder.inventory_group})
          .subscribe(({ data }) => {
            this.orderJobDetails = data;
          });
        }
      }
  }

  viewTimeline(){
    this.router.navigate(['order-timeline'])
  }

  ionViewWillEnter() {
    this.store.set('screenTitle', "Display Types");

    this.store.select('selectedOrderData').subscribe((orderData:ResponseType) => {
      this.orderType = orderData.orderType; 
      this.selectedOrder = orderData.selectedOrder;
     })
     this.getOrderJobDetails();
  }
}
