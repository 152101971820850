import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Store } from 'src/app/store';
import { Location } from "@angular/common";
import {NavController} from '@ionic/angular'

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit{
  headerTitle: String;
  isShowLogout: Boolean = true;
  show: Boolean = true;
  screenNavigation= [
    {presentScreen: 'Confirmation', backScreen: 'display-list'},
    {presentScreen: 'Installation', backScreen: 'display-list'},
    {presentScreen: 'Display Types', backScreen: 'calender'},
    {presentScreen: 'My Work', backScreen: 'adfare'},
    {presentScreen: 'Running Ads Status', backScreen: 'adfare'},
    {presentScreen: 'Re-Assign Order', backScreen: 'adfare'},
    {presentScreen: 'Adfare', backScreen: ''},
    {presentScreen: 'Field Service', backScreen: ''},
    {presentScreen: 'Advertisement Survey', backScreen: ''},
    {presentScreen: 'Ad Details', backScreen: 'inventory'},
    {presentScreen: 'Work Order Listing', backScreen: 'field-service'},
    {presentScreen: 'Work Order Details', backScreen: 'workorder-list'},
    {presentScreen: 'Submission Timeline', backScreen: 'display-list'}
  ]
  constructor(private authService : AuthService, private router: Router, private store: Store,private location: Location, public navCtrl: NavController) { }
  
  logout() {
    this.authService.deleteToken();
    this.router.navigate(['login']);
  }

  myBackButton(){

    if(this.headerTitle=='Removal'){
      this.location.back();
    }else{
      this.screenNavigation.forEach(element => {
        if(this.headerTitle===element.presentScreen){
        this.router.navigate([element.backScreen]);
        }
      }) 
    }
  }

  ngOnInit() { 
    this.store.select('screenTitle').subscribe((title:String) => {
      this.headerTitle = title;
    })
    this.isShowLogout = this.authService.isLoggedIn;
  }
}
