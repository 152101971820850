import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { LoadingController, ModalController } from '@ionic/angular';
import { Store } from 'src/app/store';
import { ApiResponse } from 'src/app/models/api-response';
import { FormValidators } from 'src/app/utils/form-validators';
import { OrderService } from '../../../services/orders.service';
import { RealDisplayType } from 'src/app/models/real-display-type';
import { CrewOrder } from 'src/app/models/crew-order';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { ResponseType } from '../../listorder/orders.component';
import { User } from 'src/app/models/user';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { PDFDocumentProxy, PDFProgressData } from 'ng2-pdf-viewer';

@Component({
  selector: 'app-upload-invoice',
  templateUrl: './upload-invoice.component.html',
  styleUrls: ['./upload-invoice.component.scss'],
})
export class UploadInvoiceComponent implements OnInit {

  orderType: string = '';
  unitDisplayTypes: RealDisplayType[] = [];
  loggedInUserID:number=null;
  selectedOrder : CrewOrder=null;
  fileAttr = 'Choose File';
  @ViewChild('fileInput') fileInput: ElementRef;

  constructor(
    private store: Store,
    private fb: FormBuilder,
    public domsanitizer: DomSanitizer,
    private orderService : OrderService,
    public geolocation: Geolocation,
    private router: Router,
    public toastService: ToastrService,
    private loadingController: LoadingController,
    private modalCtrl : ModalController
  ) { }

  loader;
  invoiceAttached : any;
  invoicebase64Path :any;
  isSubmitDisable: boolean = false;
  fgUploadInvoice: FormGroup;
  fileObj;

  closeSheet(){
    this.modalCtrl.dismiss();
  }

  findUserLocation(){ 
    let options = {
      enableHighAccuracy: true,
      timeout: 25000
    };


    return this.geolocation.getCurrentPosition(options).then((position) => {
      this.fgUploadInvoice.get('latitude').setValue(position.coords.latitude)
      this.fgUploadInvoice.get('longitude').setValue(position.coords.longitude)
     }).catch((error) => {
       console.log('Error getting location', error);
     });
  }


  uploadInvoiceFileEvent(pdfFile: any){
    this.intitilizeLoader(); 
    if(pdfFile.target.files && pdfFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(pdfFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name ;
      });
      let reader = new FileReader();
      reader.onload = (e: any) => {  
          console.log(e);
          let pdfBase64Path = e.target.result;
          this.invoicebase64Path = e.target.result;
          this.invoiceAttached = pdfBase64Path.replace(/^data:application\/[a-z]+;base64,/,'');
          this.closeLoader();
      };
      reader.readAsDataURL(pdfFile.target.files[0]);
      
      // Reset if duplicate file uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  initComponentData = async() => {
    const { required } = FormValidators;
    this.fgUploadInvoice = this.fb.group({
      notes: [''],
      latitude: [''],
      longitude: ['']
    }) 
  }  

  async intitilizeLoader() {
    this.loader = await this.loadingController.create({
      //cssClass: 'my-custom-class',
      message: 'Please wait...'
    });
    this.loader.present()
  }

  async closeLoader() {
    this.loader.dismiss();
  }

  async onSubmit() {
    this.intitilizeLoader();
    this.isSubmitDisable= true;
    await this.findUserLocation();
    let data = { ...this.fgUploadInvoice.getRawValue() };
    data.order_id = this.selectedOrder.order_id;
    data.user_id = this.loggedInUserID;
  //  data.notes = '';
    data.invoice_data = [];
    data.invoice_base64 = this.invoiceAttached;
    data.file_name = 'Order# ' + this.selectedOrder.order_number + ' Invoice.pdf';
    this.orderService.uploadInvoice(data,this.selectedOrder.order_id).subscribe(res => {
      this.isSubmitDisable= false;
      this.handleAlerts(res);
      this.closeLoader();
    });
  }

  async handleAlerts(res: ApiResponse<any>) {
    if(res.isSuccess) {
      this.toastService.success('Invoice sent successfully');
      this.modalCtrl.dismiss(); // test this 
      this.router.navigate(['display-list']);
    } else {
      this.toastService.error(res.errors);
    }
  }

  ngOnInit() {
    this.initComponentData();
  }

  ionViewWillEnter() {

    this.store.set('screenTitle', "Display Types");
    this.store.select('userInfo').subscribe((userData:User) => {
      this.loggedInUserID = userData.user_id;
    })
     this.store.select('selectedOrderData').subscribe((orderData:ResponseType) => {
      this.orderType = orderData.orderType; 
      this.selectedOrder = orderData.selectedOrder;
     })

    this.initComponentData();
  }  

}
