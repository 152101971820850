import { Inject, Injectable } from "@angular/core";
import { API_URL } from "../../../models/environment.token";
import { Store } from "../../../store";
import { HTTPService } from "../../../shared/services/http.service";
import { Pager, PagerQuery } from '../../../models/pager';

import { ApiResponse } from '../../../models/api-response';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, retry, tap } from 'rxjs/operators';
import { CrewOrder } from '../../../models/crew-order';
import {generateFilterFields,getFormData} from '../../../utils/helpers'
import { AvailableInventory, OrderJobDetail } from 'src/app/models/order-job-detail';
import { BarCodeOrderDetail } from 'src/app/models/barcode-order-detail';
import { SurveyAdvertisement } from 'src/app/models/survey-advertisement';
import { ExpiredRemovedLog } from 'src/app/models/expired-removed-log';
import { ReassignOrder } from 'src/app/models/reassign-order';
import { User } from 'src/app/models/user';
import { UploadInvoice } from 'src/app/models/upload-invoice';

@Injectable({ providedIn: "root" })
export class OrderService {
  constructor(
    private store: Store,
    private http: HTTPService,
    @Inject(API_URL) private api: string
  ) {

    
  }

  getAllCrewOrders(query: PagerQuery,filters={}, isToBeExported = false) {
    return this.http
      .get<ApiResponse<CrewOrder[]>>(
        this.api + 'crew-orders-api/crew-orders?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          return res.data
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getInstallOrderJobDetails(query: PagerQuery,filters={}, isToBeExported = false) {
    return this.http
      .get<ApiResponse<OrderJobDetail>>(
        this.api + 'installed-orders-api/installed-orders?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          return res.data
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getRemoveOrderJobDetails(query: PagerQuery,filters={}, isToBeExported = false) {
    return this.http
      .get<ApiResponse<OrderJobDetail>>(
        this.api + 'removed-orders-api/removed-orders?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          return res.data
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }


  getAvailableInventories(query: PagerQuery,filters={}, isToBeExported = false) {
    return this.http
      .post<ApiResponse<{status:Boolean, data :AvailableInventory[]}>>(
        this.api + 'order/order-management/get-available-inventories-for-ad-fare',
        filters,
        {headers: {'Content-Type': 'application/x-www-form-urlencoded'}}
      )
      .pipe(
        retry(1),
        tap(res => {
          return res.data
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  saveInstallAd(data): Observable<any> {
    return this.http.put<ApiResponse<any>>(this.api + "installed-orders-api/installed-orders/"+data.order_id, data).pipe(
      tap(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  saveAdRemoval(data): Observable<any> {
    return this.http.put<ApiResponse<any>>(this.api + "removed-orders-api/removed-orders/"+data.order_id, data).pipe(
      tap(res => {
        return res;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  getInstalledOrderByBarcode(query: PagerQuery,filters, isToBeExported = false) {
    return this.http
      .get<ApiResponse<BarCodeOrderDetail>>(
        this.api + 'installed-order-by-barcode/installed-orders/'+filters.barcode
      )
      .pipe(
        retry(1),
        tap(res => {
          return res
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getInventoryStatusDetail(query: PagerQuery,filters, isToBeExported = false) {
    return this.http
      .get<ApiResponse<BarCodeOrderDetail>>(
        this.api + 'order-by-barcodeor-or-unit-number/orders?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          return res
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  saveExpiredButNotRemovedLog(data: ExpiredRemovedLog): Observable<any> {
    return this.http
      .post<ApiResponse<number[]>>(this.api + 'expired-order-inventory/inventory', data)
      .pipe(
        tap(res => {
          if (res.isSuccess) {
            return res.data;
          }
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  orderReassign(data: ReassignOrder): Observable<any> {
    return this.http
      .post<ApiResponse<number[]>>(this.api + 'crew-orders-api/crew-orders-reassign', data)
      .pipe(
        tap(res => {
          if (res.isSuccess) {
            return res.data;
          }
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getAllCrews(query: PagerQuery,filters, isToBeExported = false) {
    return this.http
      .get<ApiResponse<User[]>>(
        this.api + 'fsm-vendor-api/fsm-vendor-crew?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          return res
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getSubmitDetails(query: PagerQuery,filters,id, isToBeExported = false) {
    return this.http
      .post<ApiResponse<{}>>(this.api + 'installed-orders-api/installed-removal-confirmation/'+id, filters)
      .pipe(
        retry(1),
        tap(res => {
          return res
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }    

  getOrderTimeline(query: PagerQuery,filters, isToBeExported = false){
    return this.http
      .post<ApiResponse<{}>>(this.api + 'installed-orders-api/orders-adfare-timeline', filters)
      .pipe(
        retry(1),
        tap(res => {
          return res
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getWorklogPdf(query: PagerQuery,filters, isToBeExported = false){
    return this.http
      .post<ApiResponse<{}>>(this.api + 'installed-orders-api/order-adfare-work-log-pdf', filters)
      .pipe(
        retry(1),
        tap(res => {
          return res
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  uploadInvoice(data: UploadInvoice,id): Observable<any> {
    return this.http
      .post<ApiResponse<{}>>(this.api + 'installed-orders-api/upload-order-invoice/'+ id, data)
      .pipe(
        tap(res => {
          if (res.isSuccess) {
            return res.data;
          }
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
