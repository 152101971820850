import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { BrowserModule} from '@angular/platform-browser'
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { CommonModule } from '@angular/common';
import { OrderComponent } from './components/listorder/orders.component';
import { OrderRoutingModule } from './orders-routing.module';
import {AdvertisementComponent} from './components/advertisement/advertisement.component';
import {DisplayListComponent} from './components/display-list/display-list.component';
import {RemoveAdComponent} from './components/remove-ad/remove-ad.component';
import{AdsurveyComponent} from './components/adsurvey/adsurvey.component'
import {AssignedOrder} from './components/listorder/assigned-order/assigned-order.component';
import {Router} from '@angular/router';
import { CalenderComponent } from './components/calender/calender.component'
import { InventoryComponent } from './components/inventory/inventory.component';
import { AdfareComponent } from './components/adfare/adfare.component';
import { ExpiredAdComponent } from './components/expired-ad/expired-ad.component';
import { ReassignComponent } from './components/reassign/reassign.component';
import { WorkorderDetailComponent } from './components/workorder-detail/workorder-detail.component';
import { WorkorderListComponent } from './components/workorder-list/workorder-list.component';
import { UpdateConcernComponent } from './components/workorder-detail/update-concern/update-concern.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { SubmitConfirmationComponent } from './components/submit-confirmation/submit-confirmation.component';
import { OrderTimelineComponent } from './components/order-timeline/order-timeline.component';
import { UploadInvoiceComponent } from './components/display-list/upload-invoice/upload-invoice.component';

@NgModule({
  declarations: [OrderComponent, AdvertisementComponent,DisplayListComponent,AdsurveyComponent,AssignedOrder, InventoryComponent,AdfareComponent, ExpiredAdComponent,CalenderComponent,ReassignComponent,WorkorderDetailComponent,WorkorderListComponent,UpdateConcernComponent,ForgotPasswordComponent,SubmitConfirmationComponent,OrderTimelineComponent,UploadInvoiceComponent],
  imports: [SharedModule,BrowserModule, OrderRoutingModule, CommonModule, PdfViewerModule],
  exports: [AssignedOrder]
})


export class OrderModule {

  constructor(public router: Router){

  }
  public show:boolean = false;

  install(){
    this.router.navigate(['order-detail']);
  }

  remove(){
    this.show=true;
    this.router.navigate(['order-detail']);
  }
}
