import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {MatStepperModule} from '@angular/material/stepper';
import { OrderComponent } from './components/listorder/orders.component';
import { InstallAdComponent } from './components/install-ad/install-ad.component';
import { RemoveAdComponent } from './components/remove-ad/remove-ad.component';
import {AdsurveyComponent} from './components/adsurvey/adsurvey.component';
import {AdvertisementComponent} from './components/advertisement/advertisement.component';
import { DisplayListComponent } from './components/display-list/display-list.component';
import { ExpiredAdComponent } from './components/expired-ad/expired-ad.component';
import { CalenderComponent } from './components/calender/calender.component';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';


const routes: Routes = [
  {
    path: 'orders',
    component: OrderComponent
  },
  
  {
    path: 'install-ad',
    component: InstallAdComponent
  },
  {
    path: 'remove-ad',
    component: RemoveAdComponent
  },
  {
    path: 'adsurvey',
    component: AdsurveyComponent
  },
  {
    path: 'advertisement',
    component: AdvertisementComponent
  },
  {
    path: 'display-list',   
    component: DisplayListComponent
  },
  {
    path: 'expired-ad',   
    component: ExpiredAdComponent
  },
  {
    path: 'calender',   
    component: CalenderComponent
  },
  {
    path: 'forgot-password',   
    component: ForgotPasswordComponent
  },
];


@NgModule({
  imports: [RouterModule.forChild(routes), MatStepperModule],
  exports: [RouterModule]
})


export class OrderRoutingModule {
}
