import { Component } from '@angular/core';
import { OrderService } from '../../services/orders.service';
import { BarcodeScanner,BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { ToastrService } from '../../../../shared/services/toastr.service';
import {Router} from '@angular/router';
import { Store } from 'src/app/store';
import { CommonService } from '../../../app/services/common.service';
import { Market } from 'src/app/models/market';
import { ExpiredRemovedLog,InventoryExpiredLogData } from 'src/app/models/expired-removed-log';
import { AuthorityContract } from 'src/app/models/authority-contract';
import { User } from 'src/app/models/user';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { environment } from '../../../../../environments/environment';
import Quagga from 'quagga';


@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss'],
})
export class InventoryComponent{

  unitNumber: string = null;
  contracts: AuthorityContract[]=[];
  loggedInUserID: number;
  barcodeValue;
  showCameraForBarcode : boolean = false;
  isEnvironmentPWA : boolean;
  markets: Market[] = [];
  model = {
    market_id : null,
    contract_id:null
  }

  constructor(public router: Router,
    private store: Store, 
    private barcodeScanner: BarcodeScanner,
    public toastService: ToastrService,
    private orderService: OrderService,    
    private commonService: CommonService,
    public geolocation: Geolocation
    ) { }

  checkPlatform(){
    console.log(environment,environment.pwa);
    this.isEnvironmentPWA = environment.pwa;
  }

  // inputBarCode(){
  //   this.orderService
  //   .getInventoryStatusDetail({},{type: 'barcode',type_value:this.barcodeValue})
  //   .subscribe(({ data,isSuccess, errors }) => {
  //     if(isSuccess) {
  //       this.store.set('searchInventoryData', data|| null);
  //       this.router.navigate(['expired-ad']);
  //     } else {
  //       this.toastService.error(errors);
  //     }
  //   })
  // }
  // onBarcodeChange(event){
  //   this.barcodeValue = event.currentTarget.value;
  // }
  
  // Barcode Scanning function for Mobile App
  scanBarCode() {
    const options: BarcodeScannerOptions = {
      torchOn: true,
      showTorchButton: true
    }
     this.barcodeScanner.scan(options).then(barcodeData => {
       if(barcodeData.text) {
        this.orderService
        .getInventoryStatusDetail({},{type: 'barcode',type_value:barcodeData.text})
        .subscribe(({ data,isSuccess, errors }) => {
          if(isSuccess) {
            this.store.set('searchInventoryData', data|| null);
            this.router.navigate(['expired-ad']);
          } else {
            this.toastService.error(errors);
          }
        })
    }
     }).catch(err => {
         console.log('Error in bar code scan', err);
     });
 }

 // Barcode Scanning function for PWA
 scan(){
  let canvas = document.getElementById('canvasArea');
  canvas.style.display = "none";

  this.showCameraForBarcode = true; 
   Quagga.init({
     inputStream: {
       constraints: {
         facingMode: 'environment'
       },
       area: { // defines rectangle of the detection
         top: '40%',   
         right: '0%', 
         left: '0%',   
         bottom: '40%'
       },
     },
   },
   (err) => {
     if (err) {
      console.log('Error in bar code scan', err)
     } else {
        Quagga.start();
        Quagga.onDetected((res) => {
          Quagga.stop();
          this.showCameraForBarcode = false;
          if(res.codeResult.code) {
            this.orderService
            .getInventoryStatusDetail({},{type: 'barcode',type_value:res.codeResult.code})
            .subscribe(({ data,isSuccess, errors }) => {
              if(isSuccess) {
                this.store.set('searchInventoryData', data|| null);
                this.router.navigate(['expired-ad']);
              } else {
                this.toastService.error(errors);
              }
            });
          }
        });
     }
   });
 }


 onSubmit() {
      if(this.unitNumber) {
        this.orderService
        .getInventoryStatusDetail({},{type: 'unit_number',type_value:this.unitNumber, market_id: this.model.market_id, contract_id: this.model.contract_id})
        .subscribe(({ data,isSuccess, errors }) => {
          if(isSuccess) {
            const inventoryLogdata = [];
            data.forEach(ordersData => {
              if(ordersData.display_type_data.status==='Expired but not Removed') {
                ordersData.display_type_data.unit_details.forEach(unitDetail => {
                  inventoryLogdata.push(
                    {
                      "real_display_type_id":unitDetail.real_display_type_id,
                      "real_ad_type_id":unitDetail.real_ad_type_id,
                      "real_inventory_id":unitDetail.real_inventory_id,
                      "order_id":ordersData.order_data.order_id,
                      "inventory_group":ordersData.order_data.inventory_group,
                      "bar_code":ordersData.order_data.bar_code
                    }
                  )
                });
              }
            });

            this.saveExpiredButNotRemovedLogData(inventoryLogdata);
            this.store.set('searchInventoryData', data|| null);
            this.router.navigate(['expired-ad']);
          } else {
            this.toastService.error(errors);
          }
        })
      }
  }

  saveExpiredButNotRemovedLogData(inventoryLogdata: InventoryExpiredLogData[]) {
    if(inventoryLogdata.length>0) {
      const options = {
        enableHighAccuracy: true,
        timeout: 25000
      };
      return this.geolocation.getCurrentPosition(options).then((position) => {

        const inventoryOrderLogData: ExpiredRemovedLog = {
          latitude:position.coords.latitude,
          longitude:position.coords.longitude,
          user_id:this.loggedInUserID,
          ip_address:"",
          inventory_data: inventoryLogdata
        }
        this.orderService
        .saveExpiredButNotRemovedLog(inventoryOrderLogData)
        .subscribe(({ isSuccess,errors:errorsdata }) => {
          if(!isSuccess) {
            this.toastService.error(errorsdata);
          }}
        )
       }).catch((error) => {
         console.log('Error getting location', error);
       });
    }
  }

  getAllContracts() {
    this.model.contract_id = null;
    if(this.model.market_id) {
      this.commonService
        .getAllContracts({},{market_id: this.model.market_id})
        .subscribe(({ data }) => {
          this.contracts= data;
        });
      }
  }

  getAllMarkets() {
    this.commonService
      .getAllMarkets({})
      .subscribe(({ data }) => {
        this.markets= data;
      });
  }

  ionViewWillEnter() {
    this.store.set('screenTitle','Running Ads Status');
    this.getAllMarkets();
  }

  ngOnInit() {
   

    this.store.select('userInfo').subscribe((userData:User) => {
        this.loggedInUserID = userData.user_id;
    })
  }

}
