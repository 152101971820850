import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/auth/services/auth.service';
import { Menulinks } from './../../constants';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  navigate: any[];

  constructor(private authService : AuthService, private router: Router) { }

  ngOnInit() {
    this.navigate = Menulinks;
  }
  logout() {
    this.authService.deleteToken();
    this.router.navigate(['login']);
  }
}
