import { Component, OnInit} from '@angular/core';
import {Store} from 'src/app/store';
import {Router} from '@angular/router';


@Component({
  selector: 'app-adfare',
  templateUrl: './adfare.component.html',
  styleUrls: ['./adfare.component.scss'],
})
export class AdfareComponent {

  constructor(private store: Store, public router: Router) { }

  checkInventory(){
    this.router.navigate(['inventory']);
  }

  myWork(){
    this.router.navigate(['calender']);
  }
  
  reassignOrder(){
    this.router.navigate(['reassign']);
  }


  ionViewWillEnter() {
    this.store.set('screenTitle',"Adfare");
  }

}
