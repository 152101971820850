import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';
import { Store } from 'src/app/store';

@Component({
  selector: 'app-field-service',
  templateUrl: './field-service.component.html',
  styleUrls: ['./field-service.component.scss'],
})
export class FieldServiceComponent  {

  constructor(public router: Router, private store: Store) { }

  myWork(){
    this.router.navigate(['workorder-list']);
  }
  
  ionViewWillEnter() {
    this.store.set('screenTitle',"Field Service");
  }

}
