import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AppLoginComponent } from '../app/pages/app/components/login/app.login.component';
import { AppNotfoundComponent } from '../app/pages/app/components/notfound/app.notfound.component';
import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';
import {ActivateAuthGuard} from './auth/guards/activate-auth.guard';
import {InstallAdComponent} from './pages/orders/components/install-ad/install-ad.component';
import {OrderComponent} from './pages/orders/components/listorder/orders.component';
import {MatStepperModule} from '@angular/material/stepper';
import {RemoveAdComponent} from './pages/orders/components/remove-ad/remove-ad.component';
import {AdsurveyComponent} from './pages/orders/components/adsurvey/adsurvey.component';
import {AdvertisementComponent} from './pages/orders/components/advertisement/advertisement.component';
import { DisplayListComponent } from './pages/orders/components/display-list/display-list.component';
import { ExpiredAdComponent } from './pages/orders/components/expired-ad/expired-ad.component';
import { AdfareComponent } from './pages/orders/components/adfare/adfare.component';
import { CalenderComponent} from './pages/orders/components/calender/calender.component';
import { InventoryComponent } from './pages/orders/components/inventory/inventory.component';
import {ForgotPasswordComponent} from './pages/orders/components/forgot-password/forgot-password.component';
import { ReassignComponent } from './pages/orders/components/reassign/reassign.component';
import {FieldServiceComponent} from './pages/orders/components/field-service/field-service.component';
import {WorkorderListComponent} from './pages/orders/components/workorder-list/workorder-list.component';
import { WorkorderDetailComponent } from './pages/orders/components/workorder-detail/workorder-detail.component';
import { SubmitConfirmationComponent } from './pages/orders/components/submit-confirmation/submit-confirmation.component';
import { OrderTimelineComponent } from './pages/orders/components/order-timeline/order-timeline.component';
 
const appRoutes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      // {
      //   path: '',
      //   loadChildren: () => import('./pages/orders/orders.module').then(m => m.OrderModule)
      // },
      // {
      //   path: '',
      //   component: RemoveAdComponent
      // },
       {
        path: '',
        component: AdsurveyComponent
      },
      {
        path: 'orders',
        component: OrderComponent
      },
      {
        path: 'inventory',
        component: InventoryComponent
      },
      
      {
        path: 'advertisement',
        component: AdvertisementComponent
      },
      {
        path: 'install-ad',
        component: InstallAdComponent
      },
      {
        path: 'remove-ad/:source',
        component: RemoveAdComponent
      },
      {
        path: 'display-list',
        component: DisplayListComponent
      },
      {
        path: 'expired-ad',
        component: ExpiredAdComponent
      },
      {
        path: 'calender',   
        component: CalenderComponent
      },
      {
          path: 'adfare',   
          component: AdfareComponent
      },
      {
        path: 'forgot-password',
        component: ForgotPasswordComponent
      },
      {
        path: 'field-service',
        component: FieldServiceComponent
      },
      {
        path: 'reassign',
        component: ReassignComponent
      },
      {
        path: 'workorder-list',
        component: WorkorderListComponent  
      },
      {
        path: 'workorder-detail',
        component: WorkorderDetailComponent
      },
      {
        path: 'submit-confirmation',
        component: SubmitConfirmationComponent
      },
      {
        path: 'order-timeline',
        component: OrderTimelineComponent
      }
    ]
  },
  { path: 'notfound', component: AppNotfoundComponent },
  { path: 'login', component: AppLoginComponent },
  { path: '**', redirectTo: '/notfound' },
  
];

[...appRoutes].forEach(r => (r.canActivate = [ActivateAuthGuard]));

export const routes: Routes = [
  ...appRoutes
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }), MatStepperModule,
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
