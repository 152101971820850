import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/store';
import { environment } from '../../../../../environments/environment';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { ResponseType } from '../listorder/orders.component';
import { CrewOrder } from 'src/app/models/crew-order';
import { OrderService } from '../../services/orders.service';
import { File } from '@ionic-native/file/ngx';
import { LoadingController, Platform } from '@ionic/angular';
import { Downloader, DownloadRequest, NotificationVisibility } from '@ionic-native/downloader/ngx';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-order-timeline',
  templateUrl: './order-timeline.component.html',
  styleUrls: ['./order-timeline.component.scss'],
})

export class OrderTimelineComponent implements OnInit {

  constructor(
    private store: Store,
    private pdfGenerator: PDFGenerator,
    private toastrService: ToastrService,
    private orderService: OrderService,
    private loadingController: LoadingController,
    private file: File,
    private platform: Platform,
    private downloader: Downloader
  ) { }
  
  loader;
  selectedOrder : CrewOrder=null;
  createdDate = new String;
  taskStartDate = new Date;
  taskendDate = new Date;
  isEnvironmentPWA : boolean;
  orderType: string = '';
  orderTimeline;
  orderPdf;
  isPreInstallPhotos: boolean = false;
  isPostInstallPhotos: boolean = false;
  isPreRemovalPhotos: boolean = false;
  isProstRemovalPhotos: boolean = false;
 

  checkPlatform(){
    console.log('Platform'+ this.platform)
    console.log(environment,environment.pwa);
    this.isEnvironmentPWA = environment.pwa;
  }

  getworklogPdf(){
    this.orderService
    .getWorklogPdf({},{order_id: this.selectedOrder.order_id,action_type: this.orderType
    })
    .subscribe(res => {
      this.orderPdf = res.data;
      if(this.isEnvironmentPWA){
        this.downloadPDF();
      }else if(!this.isEnvironmentPWA){
        if(this.platform.is('android')){
        this.androidDownload();
        }else if(this.platform.is('ios')){
          this.downloadPDFfromApp();
        }
      }
    });
  }

  androidDownload(){
    var request: DownloadRequest = {
      uri: this.orderPdf.pdf_signed_url,
      title: 'Order# '+this.selectedOrder.order_number+' WorkLog.pdf',
      description: '',
      mimeType: '',
      visibleInDownloadsUi: true,
      notificationVisibility: NotificationVisibility.VisibleNotifyCompleted,
      destinationInExternalFilesDir: {
          dirType: 'Downloads',
          subPath: 'MyFile.pdf'
      }
    };
    this.intitilizeLoader();
    this.downloader.download(request).then((location: string) => {
      this.closeLoader();
      this.toastrService.success('File downloaded successfully');
    })
    .catch((error: any) => this.toastrService.error(error));

  }

  downloadPDF(){
    const pdfUrl = this.orderPdf.pdf_signed_url;
    const pdfName = 'Order# ' + this.selectedOrder.order_number +' WorkLog PDF';
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  downloadPDFfromApp(){
    this.intitilizeLoader();
    let options = {
      documentSize : 'A4',
      type: 'share',
      fileName: 'Order# ' + this.selectedOrder.order_number +' WorkLog PDF'
    };
    this.pdfGenerator.fromURL(this.orderPdf.pdf_signed_url,options).then((base64) => {
      console.log('Successful',base64);
      this.closeLoader();
    }).catch((error) => {
      console.log('err',error);
    });
  }

  get timeline () {
    const timeline = [];
    if(this.orderTimeline) {
      const keys = Object.keys(this.orderTimeline);
      keys.map(key => {
        timeline.push(this.orderTimeline[key])
      })
    }
    return timeline;
  }

  // get preInstallTimelineImages () {

  //   const preInstallTimelineImages = [];
  //   if(this.timeline){
  //    this.timeline.forEach( unit => {
  //     unit.timeline_images.forEach(image => {
  //       if(image.image_action_type=='Pre-Install')
  //       preInstallTimelineImages.push(image)
  //     });
  //    })
  //   }
  //   console.log(preInstallTimelineImages)
  //   return preInstallTimelineImages;
  // }

  // get postInstallTimelineImages () {

  //   const postInstallTimelineImages = [];
  //   if(this.timeline){
  //     this.timeline.forEach( unit => {
  //       unit.timeline_images.forEach(image => {
  //         if(image.image_action_type=='Post-Install')
  //         postInstallTimelineImages.push(image)
  //       });
  //      })
  //   }
  //   console.log(postInstallTimelineImages)
  //   return postInstallTimelineImages;
  // }

  getTimeline(){
    if(this.selectedOrder) {
      this.orderService
      .getOrderTimeline({},{order_id: this.selectedOrder.order_id,action_type: this.orderType
      })
      .subscribe(({ data }) => {
        this.orderTimeline = data;

      });
    }
  }


  async intitilizeLoader() {
    this.loader = await this.loadingController.create({
      //cssClass: 'my-custom-class',
      message: 'PDF Loading...'
    });
    this.loader.present()
  }

  async closeLoader() {
    this.loader.dismiss();
  }

  ngOnInit() {
    this.checkPlatform();
  }

  ionViewWillEnter() {
    this.store.set('screenTitle', "Submission Timeline");
    this.store.select('selectedOrderData').subscribe((orderData:ResponseType) => {
      this.orderType = orderData.orderType; 
      this.selectedOrder = orderData.selectedOrder;
      if(this.orderType=='Remove'){
        this.orderType = 'Removal'
      }
     })
     this.getTimeline();
  }  

}
