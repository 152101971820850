import {Component, OnInit} from '@angular/core';
import { Store } from 'src/app/store';
import {NavigationEnd, Router} from '@angular/router';
import { AuthorityContract } from 'src/app/models/authority-contract';
import { Market } from 'src/app/models/market';
import { CommonService } from '../../../app/services/common.service';
import { OrderService } from '../../services/orders.service';
import { User } from 'src/app/models/user';
import { CrewOrder } from 'src/app/models/crew-order';
import { BarcodeScanner,BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { ToastrService } from '../../../../shared/services/toastr.service';


export interface ResponseType  {
  orderType : string;
  selectedOrder : CrewOrder
}

@Component({
  selector: 'app-orders',
  templateUrl: 'orders.html',
  styleUrls: ['orders.scss']
})
export class OrderComponent implements  OnInit{
  constructor(private store: Store, public router: Router,private commonService: CommonService,private orderService: OrderService,private barcodeScanner: BarcodeScanner,public toastService: ToastrService,) {
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.getCrewOrders();
      }
    });
  }
  public installed:boolean = false;
  public removed:boolean = false;
  contracts: AuthorityContract[]=[];
  crewInstallOrders: CrewOrder[] = [];
  crewRemovalOrders: CrewOrder[] = [];
  loggedInUserID: number = null;
  markets: Market[] = [];
  model = {
    market_id : null,
    contract_id:null
  }

  installOrder(){
   this.installed=true;  
   this.removed=false;  
  }

  removeOrder(){
   this.installed=false;  
   this.removed=true;
  }

  getAllContracts() {
    this.model.contract_id = null;
    if(this.model.market_id) {
      this.commonService
        .getAllContracts({},{market_id: this.model.market_id})
        .subscribe(({ data }) => {
          this.contracts= data;
        });
      }
  }
  
  scanBarCode() {
    const options: BarcodeScannerOptions = {
      torchOn: true,
      showTorchButton: true
    }
      this.barcodeScanner.scan(options).then(barcodeData => {
        if(barcodeData.text) {
          this.orderService
          .getInstalledOrderByBarcode({},{barcode: barcodeData.text})
          .subscribe(({ data,isSuccess, errors }) => {
            if(isSuccess) {
              const selectedOrderData = {orderType:'Remove', selectedOrder: data.order_data };
              this.store.set('selectedOrderData', selectedOrderData|| null)
              this.store.set('selectedOrderDisplayType', data.display_type_data || null)
              this.router.navigate(['remove-ad']);
            } else {
              this.toastService.error(errors);
            }
          })
        }
      }).catch(err => {
          console.log('Error in bar code scan', err);
      });
   }

   scanBarCodeExpiry() {
    const options: BarcodeScannerOptions = {
      torchOn: true,
      showTorchButton: true
    }
    this.barcodeScanner.scan(options).then(barcodeData => {
      if(barcodeData.text) {
        this.orderService
        .getInstalledOrderByBarcode({},{barcode:barcodeData.text})
        .subscribe(({ data,isSuccess, errors }) => {
          if(isSuccess) {
            // const selectedOrderData = {orderType:'Remove', selectedOrder: data.order_data };
            // this.store.set('selectedOrderData', selectedOrderData|| null)
            // this.store.set('selectedOrderDisplayType', data.display_type_data || null)
            this.router.navigate(['expired-ad']);
          } else {
            this.toastService.error(errors);
          }
        })
     }
    }).catch(err => {
        console.log('Error in bar code scan', err);
    });
 }

  getAllMarkets() {
    this.commonService
      .getAllMarkets({})
      .subscribe(({ data }) => {
        this.markets= data;
      });
  }

  getCrewOrders() {
    if(this.model.contract_id) {
      this.orderService
        .getAllCrewOrders({},{contract_id: this.model.contract_id,crew_id : this.loggedInUserID})
        .subscribe(({ data }) => {
          this.crewInstallOrders = data.filter(order => order.order_type_category==='Install');
          this.crewRemovalOrders = data.filter(order => order.order_type_category==='Removal');
        });
      }
  }

  // On install/remove order selected
  onOrderSelected(orderData: ResponseType) {
      this.store.set('selectedOrderData', orderData || null)
      this.router.navigate(['display-list']);
  }

  ngOnInit() {
    this.store.select('userInfo').subscribe((userData:User) => {
     this.loggedInUserID = userData.user_id;
    })
    this.store.set('screenTitle', "Orders Listing Page");
    this.getAllMarkets();
  }
}