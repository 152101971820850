import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { FormValidators } from '../../../../utils/form-validators';
import { Login } from '../../../../models/user';
import { UserService } from '../../services/user.service';
import { ApiResponse } from '../../../../models/api-response';
import { ToastrService } from '../../../../shared/services/toastr.service'
import { Store } from 'src/app/store';
import {Md5} from "md5-typescript";
import { max } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';  
import { SplashScreen } from '@ionic-native/splash-screen/ngx';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.scss']
})
export class AppLoginComponent implements  OnInit{
  fgLogin: FormGroup;
  constructor(
    private router: Router,
    private fb: FormBuilder,
    private userService: UserService,
    public toastService: ToastrService,
    private store: Store,
    public iab: InAppBrowser,
    public splashScreen: SplashScreen,
    private platform: Platform
  ) { 
  }

  // login() {  
  //   this.splashScreen.show();  
  
  //   const url = 'https://login.microsoftonline.com/common/oauth2/authorize?client_id='  
  //     + "c5e067c8-ba11-42f7-95ef-6941e0c743df"+ //here need to paste your client id  
  //     '&response_type=code&redirect_uri=' 
  //     + encodeURI("http://localhost:8100/adsurvey") + //here encoding redirect url using default function  
  //     '&response_mode=query&resource='  
  //     + encodeURI("https://login.microsoftonline.com") + //here encoding resource url using default function  
  //     '&state=de5e4146d57287a99b73fdfc7447be6a\u0026';  
   
  //   const browser = this.iab.create(url, '_blank', {  
  //     location: 'no',  
  //     zoom: 'no',  
  //     hardwareback: 'no',  
  //     clearcache: 'yes'  
  //   });  
  
  //   browser.on("loadstart").subscribe((event) => {  
  //     this.splashScreen.show();  
  //   });  
  
  //   browser.on("loadstop").subscribe((event) => {  
  //     this.splashScreen.hide();  
  //     browser.show();  
  //   });  
  
  //   browser.on("loaderror").subscribe((event) => {  
      
  //     var result = event.url.split("code=");        
  //     console.log("Authentication result", result); 

  //     window["AuthResult"] = result[1].split('&')[0];       
    
  //     // It means get access token and refresh token for microsoft sharepoint.        
  //     localStorage.setItem('AuthCode', window["AuthResult"]);    
  //     browser.close();  
  //   });  
  //   browser.on("exit").subscribe(  
  //     (event) => {  
          
  //       if (event) {  
  //         if (event.type.toLowerCase() == 'exit') {  
  //           if (localStorage.getItem("AuthCode") && localStorage.getItem("AuthCode") == 'cancel') {  
  //             this.platform.backButton.subscribe(() => {
  //                          navigator['app'].exitApp();
  //              }); 
  //             event.preventDefault();  
  //             return true;  
  //           }  
  //         }  
  //       }  
  //     },  
  //     (err) => {  
  //       console.log("InAppBrowser Loadstop Event Error: " + err);  
  //     }  
  //   );  
  // }  

  ngOnInit() {
    const { required } = FormValidators;

    this.fgLogin = this.fb.group({
      username: ['', []],
      password: ['', []],
      user_type: ['Crew'],
      ip_address : ['']
    });
    this.store.set('screenTitle', "Log In");
  }
  forgotPassword(){
    this.router.navigate(['forgot-password']);
  }

  office365Login(){
    
  }

  onSubmit() {
    let data = { ...this.fgLogin.getRawValue() } as Login;
    data.password = Md5.init(data.password);
    this.userService.loginUser(data).subscribe(res => this.handleAlerts(res));
  }

  async handleAlerts(res: ApiResponse<any>) {
    if (res.isSuccess) {
      this.router.navigate(['adsurvey']); 
    } else {
      this.toastService.error(res.errors);
    }
  }
}
