import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';


import { FcErrorDirective } from './directives/form-controls/fc-error.directive';

const sharedComponentsDirectives = [
  FcErrorDirective
];

const sharedModules = [
  CommonModule,
  RouterModule,
  FormsModule,
  IonicModule,
  ReactiveFormsModule,
];


@NgModule({
  imports: [...sharedModules],
  exports: [...sharedModules, ...sharedComponentsDirectives],
  declarations: [...sharedComponentsDirectives]
})
export class SharedModule { }
