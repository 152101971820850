import { Injectable } from "@angular/core";
import { ToastController } from '@ionic/angular';

@Injectable({ providedIn: "root" })
export class ToastrService {
  constructor(private toastController: ToastController) { }

  success(message: string | string[]) {
    this.showToaster({
      header: "Success",
      message: message,
      color: "success",
      duration: 5000
    });
  }

  error(message: string | string[]) {
    this.showToaster({
      header: "Error",
      message: message,
      color: "danger",
      duration: 5000
    });
  }

  info(message: string | string[]) {
    this.showToaster({
      header: "Info",
      message: message,
      color: "light",
      duration: 5000
    });
  }

  async showToaster(config) {
    const message = this.parseMessage(config.message);
    const toast = await this.toastController.create({ ...config, message });
    toast.present();
  }

  parseMessage(message: string | string[]): string {
    if (Array.isArray(message))
      return (
        message
          .filter(m => m && m.trim())
          .join("\n")
          .trim() || "Some error occurred"
      );
    else return message;
  }
}
