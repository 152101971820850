const DEFAULT_URL = "https://mediauat.gus.cloud",
  DEFAULT_FSM_URL = "https://fsm-backend.gus.cloud",
  api = "";

const hostUrl = (useDefault = false, urlType = 'media') => {
  const referrerUrl = document.referrer
    .split("/")
    .slice(0, 3)
    .join("/")
    .trim();
  if (useDefault || referrerUrl.toLowerCase().includes("localhost"))
    return urlType === 'media' ? DEFAULT_URL : DEFAULT_FSM_URL;
  else return referrerUrl;
};

export const API_URL = (useDefault = false) => {
  const url = hostUrl(useDefault);
  return [url, api].join("/").trim();
};

export const FSM_API_URL = (useDefault = false, urlType = 'fsm') => {
  const url = hostUrl(useDefault, urlType);
  return [url, api].join("/").trim();
};
