import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  RouterStateSnapshot,
  Router
} from '@angular/router';

import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

const routesLoggedInNotRequired = [
  '/error',
  '/accessdenied',
  '/notfound',
  '/login',
  '/forgot-password'
  /*'/orders',
  '/install-ad',
  '/remove-ad',
  '/advertisement'*/
];
@Injectable()
export class ActivateAuthGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (routesLoggedInNotRequired.indexOf(state.url) > -1) {
      if (!this.authService.isLoggedIn) { return !this.authService.isLoggedIn; }
      else { this.router.navigate(['']); }
    } else {
      if (this.authService.isLoggedIn) { return this.authService.isLoggedIn; }
      else { this.router.navigate(['login']); }
    }
  }
}
