import { Menu } from '../../models/menu';
export const Menulinks: Menu[] = [
  {
    title: "Orders",
    url: "/orders",
    icon: "basket"
  },
  {
    title: "Home",
    url: "/home",
    icon: "car"
  }
];
