import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from 'src/app/store';
import { User } from 'src/app/models/user';
import { WorkOrderService } from '../../services/work-order.service';
import { WorkOrder } from 'src/app/models/work-order.types';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-workorder-list',
  templateUrl: './workorder-list.component.html',
  styleUrls: ['./workorder-list.component.scss'],
})
export class WorkorderListComponent{

  constructor(private store: Store,public router: Router, private workOrderService: WorkOrderService) { }

  selectedDate: string = null;
  dd: number = null;
  mm: string = null;
  month: number=null;
  y: number = null;
  loggedInUserID: number = null;
  counter = 0;
  workOrders: WorkOrder[] = [];
  userInfoSubscriber: Subscription;
  workOrderSubscriber: Subscription;
  
  changeDate(type) {
    if(type === 'prev') {
     this.counter -=1;
    }  else if(type==='next'){
     this.counter +=1; 
    }
    const currentDate = new Date();
    currentDate.setDate(currentDate.getDate() + this.counter); 

    this.dd = currentDate.getDate();
    this.month = currentDate.getMonth() + 1;
    this.mm = currentDate.toLocaleString('en-us',{month: 'long'});
    this.y = currentDate.getFullYear();
    this.selectedDate = this.month + '/'+ this.dd + '/'+ this.y;
    //this.getCrewOrders();   here work orders should be there
  }

  getWorkOrders() {
    if(this.loggedInUserID) {
      this.workOrderSubscriber = this.workOrderService
        .getAllWorkOrders({},{crew_id : this.loggedInUserID})  // work_order_date:`${this.y}-${this.mm}-${this.dd}`
        .subscribe(({ data }) => {
          this.workOrders = data;
        });
      }
  }

  // On install/remove order selected
  onOrderSelected(workOrder: WorkOrder) {
      this.store.set('selectedWorkOrder', workOrder || null)
      this.router.navigate(['workorder-detail']);
  }

  ionViewWillEnter() {
    this.store.set('screenTitle',["Work Order Listing"]);
    this.changeDate('init');
    this.userInfoSubscriber = this.store.select('userInfo').subscribe((userData:User) => {
      this.loggedInUserID = userData.user_id;
      this.getWorkOrders();
     })
  }

  ionViewWillLeave() {
    this.userInfoSubscriber.unsubscribe();
    this.workOrderSubscriber.unsubscribe();
  }
}
