import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiResponse } from './../../models/api-response';
import { PagerQuery } from './../../models/pager';

@Injectable({ providedIn: 'root' })
export class ApiResponseHandlerService implements HttpInterceptor {

  constructor() { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      map((res: HttpResponse<ApiResponse<any>>) => this.mapHttpResponseToApiResponse(res))
    );
  }

  mapHttpResponseToApiResponse(res: HttpResponse<ApiResponse<any>>) {
    if (res && res.body) {
      const { body } = res;
      const keys = Object.keys(body);
      let result: ApiResponse<any> = null;
    //  console.log(res);
      if (keys.includes('status')) {
        const status = Number(body.status); // If response is NOT in ApiResponse format (check using "status")

        if (status === 200 || status === 201) // map as Success
          result = {
            statusCode: 200,
            isSuccess: true,
            data: body.data ? body.data : body,
            message: body.message || 'Success',
            pager: this.mapPageReponse(body)
          };
        else
          result = { // map as Error
            statusCode: status,
            isSuccess: false,
            data: [],
            errors: body.errors || [body.error || body.message],
            message: 'Error',
            pager: null
          };

      } else if (!keys.includes('statusCode')) {  // If response is NOT in ApiResponse format (check using "statusCode")
        if (keys.includes('Error')) { // Check or exception error
          result = {
            statusCode: 500,
            isSuccess: false,
            data: null,
            errors: [body.error || body.message || 'Some error occurred. Please try again!'],
            message: 'Success',
            pager: this.mapPageReponse(body)
          };
        } else {
          result = {
            statusCode: 200,
            isSuccess: true,
            data: body,
            message: 'Success',
            pager: this.mapPageReponse(body)
          };
        }
      } else if (keys.includes('statusCode')) {  // If response is in ApiResponse format
        result = {
          ...body,
          isSuccess: +body.statusCode === 200,
          pager: this.mapPageReponse(body)
        };
      }
      const respObj = { ...res, body: result };
      return new HttpResponse(respObj);
    }
    return res;
  }

  mapPageReponse(body): PagerQuery {
    if (!body) return null;
    const { page } = body;
    if (!page) return null;
    const { size, number, totalElements, totalPages, search } = page;
    return {
      pageSize: +size,
      pageNumber: +number,
      totalItems: +totalElements,
      totalPages: +totalPages,
      search
    };
  }
}
