import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { environment } from '../../../environments/environment';
import { Store } from "../../store";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(
    private store: Store
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token =  environment.fsm_basic_auth.user+":"+environment.fsm_basic_auth.password;
    const hash = btoa(token); 
    const extraHeaders = (req.url.indexOf('-backend')>-1) ? {Authorization: "Basic " + hash,UserType:'CREW',UserId:btoa(localStorage.getItem('userId').toString())}:{Authorization: 'Bearer '+AuthService.authToken};
    const clonedRequest = req.clone({
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        ...extraHeaders
      })
    });
    return next.handle(clonedRequest);
  }
}
