import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { environment } from '../environments/environment';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { OrderModule } from './pages/orders/orders.module';
import { AuthModule } from './auth/auth.module';
import { CommonModule } from '@angular/common';
import {AdvertisementComponent} from './pages/orders/components/advertisement/advertisement.component'
import { OrderComponent } from './pages/orders/components/listorder/orders.component';
import { InstallAdComponent } from './pages/orders/components/install-ad/install-ad.component';
import {DisplayListComponent} from './pages/orders/components/display-list/display-list.component';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { IonicStorageModule } from '@ionic/storage';

import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';  
import { AppMenuComponent } from '../app/pages/app/components/menu/app.menu.component';
import { AppLoginComponent } from '../app/pages/app/components/login/app.login.component';
import { HeaderComponent } from '../app/pages/app/components/header/header.component';
import { Geolocation } from '@ionic-native/geolocation/ngx';

// Layout components

import { SiteLayoutComponent } from './layout/site-layout/site-layout.component';


import { API_URL, FSM_API_URL } from './models/environment.token';
import { ApiResponseHandlerService } from './shared/services/api-response-handler.service';
import { RequestMockerService } from './shared/services/request-mocker.service';
import { Store } from './store';
import { MenuService } from '../app/pages/app/services/app.menu.service';
import { Camera} from '@ionic-native/camera/ngx';
import { RemoveAdComponent } from './pages/orders/components/remove-ad/remove-ad.component';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { ServiceWorkerModule } from '@angular/service-worker';
import { MatFileUploadModule } from 'angular-material-fileupload';
import { IonSelectSearchLibModule } from 'ionic-select-search';
import { PDFGenerator } from '@ionic-native/pdf-generator/ngx';
import { File } from '@ionic-native/file/ngx';
import { Downloader } from '@ionic-native/downloader/ngx';

@NgModule({
  declarations: [AppComponent,
    AppLoginComponent,
    AppMenuComponent,
    HeaderComponent,
    InstallAdComponent,
    RemoveAdComponent,
    SiteLayoutComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonSelectSearchLibModule,
    MatFileUploadModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    SharedModule,
    OrderModule,
    CommonModule,
    AuthModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [
    Store,
    PDFGenerator,
    Downloader,
    Camera,
    { provide: API_URL, useValue: environment.api_url },
    { provide: FSM_API_URL, useValue: environment.fsm_api_url },
    { provide: HTTP_INTERCEPTORS, useClass: RequestMockerService, multi: true },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiResponseHandlerService,
      multi: true
    },
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    NativeStorage,
    MenuService,
    InAppBrowser,
    BarcodeScanner,
    Geolocation,
    File,

  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
