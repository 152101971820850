import {Component,Input,Output,EventEmitter} from '@angular/core';
import { CrewOrder } from 'src/app/models/crew-order';

interface ResponseType  {
    orderType : string;
    selectedOrder : CrewOrder
}
@Component({
  selector: 'assigned-order',
  templateUrl: 'assigned-order.html',
  styleUrls: ['assigned-order.scss']
})
export class AssignedOrder{
  constructor() {
  }
  assignedOrders: CrewOrder[] = [];
  @Input() orders: CrewOrder[]; 
  @Input() orderType: string = 'Install';
  @Input() isShow: Boolean = false;
  @Output() orderSelected = new EventEmitter<ResponseType>();

  onClickOrder(order:CrewOrder) {
    this.orderSelected.emit({orderType:this.orderType,selectedOrder:order})
  }
  
 }