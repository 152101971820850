
import { Component } from '@angular/core';
import { Store } from 'src/app/store';
import { OrderService } from '../../services/orders.service';
import { User } from 'src/app/models/user';
import { CrewOrder } from 'src/app/models/crew-order';
import { Router } from '@angular/router';


export interface ResponseType  {
  orderType : string;
  selectedOrder : CrewOrder
}
@Component({
  selector: 'app-calender',
  templateUrl: './calender.component.html',
  styleUrls: ['./calender.component.scss'],
})
export class CalenderComponent {

  crewInstallOrders: CrewOrder[] = [];
  crewRemovalOrders: CrewOrder[] = [];
  //allOrders: CrewOrder[] = [];
  loggedInUserID: number = null;
  selectedDate: string = null;
  dd: number = null;
  mm: number = null;
  month: string = null;
  y: number = null;
  counter = 0;

  constructor(private store:Store,private orderService: OrderService,private router: Router) {
   }

   // On Next or previous date
   changeDate(type) {
     if(type === 'prev') {
      this.counter -=1;
     }  else if(type==='next'){
      this.counter +=1; 
     }
     const currentDate = new Date();
     currentDate.setDate(currentDate.getDate() + this.counter); 

    this.dd = currentDate.getDate();
    this.mm = currentDate.getMonth() + 1;
    this.month = currentDate.toLocaleString('en-us',{month: 'long'});
    this.y = currentDate.getFullYear();
    this.selectedDate = this.mm + '/'+ this.dd + '/'+ this.y;
    this.getCrewOrders();
   }

   getCrewOrders() {
      this.orderService
        .getAllCrewOrders({},{crew_id : this.loggedInUserID, assign_date: this.selectedDate})
        .subscribe(({ data }) => {
          this.crewInstallOrders = data.filter(order => order.order_type_category==='Install');
          this.crewRemovalOrders = data.filter(order => order.order_type_category==='Removal');
          //this.allOrders = data;
        });
  }

  /*getFilteredOrders = () => {
    return this.allOrders;
  }*/

   // On install/remove order selected
   onOrderSelected(orderType,orderData: CrewOrder) {
        this.store.set('selectedOrderData', {orderType: orderType,selectedOrder: orderData} || null)
        this.router.navigate(['display-list']);
    }

  inventory(){
    this.router.navigate(['expired-ad'])
  }
  
  ionViewWillEnter() {
    this.store.set('screenTitle', "My Work");
    this.store.select('userInfo').subscribe((userData:User) => {
      this.loggedInUserID = userData.user_id;
     })
     this.changeDate('init');
     this.getCrewOrders();
  }

}
