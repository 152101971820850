import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuService } from '../../pages/app/services/app.menu.service';

@Component({
  selector: 'app-site-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss']
})
export class SiteLayoutComponent implements OnInit {
  constructor(private menuService: MenuService) { }
  ngOnInit() { }
}
