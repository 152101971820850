import {
  Directive,
  ElementRef,
  Input,
  OnDestroy,
  Renderer2,
  OnInit
} from "@angular/core";
import { NgControl } from "@angular/forms";
import { Subscription } from "rxjs";
import { distinctUntilChanged } from "rxjs/operators";
import { hasRequiredValidator, randomId } from "../../../utils/helpers";
import { FormErrors } from "../../../models/form-errors";

@Directive({
  selector: "[fcError]"
})
export class FcErrorDirective implements OnDestroy, OnInit {
  private label: string;
  private errorElement: HTMLElement;
  private subControl: Subscription;

  private get nativeElement() {
    return this.elementRef.nativeElement;
  }

  private get ctrlHasErrors(): boolean {
    const { errors, pristine, dirty, touched, invalid } = this.control;
    return errors && (!pristine || dirty || touched || invalid);
  }

  @Input()
  set fcError(customLabel: string) {
    this.label = customLabel;
  }

  ngOnInit() {
    this.setHasMandatoryClass();
    this.subControl = this.control.statusChanges
      .pipe(distinctUntilChanged())
      .subscribe(() => {
        this.manipulateErrorElement();
      });
  }

  // NOTE: To access FormGroup instance, use either ControlContainer.control OR FormGroupDirective.form
  constructor(
    private control: NgControl,
    private renderer: Renderer2,
    private elementRef: ElementRef<HTMLElement>
  ) { }

  private manipulateErrorElement() {
    this.setHasMandatoryClass();
    if (this.ctrlHasErrors && !this.errorElement) {
      this.createErrorElement();
    } else if (this.ctrlHasErrors && this.errorElement) {
      this.updateErrorElement();
    } else {
      this.removeErrorElement();
    }
  }

  private setHasMandatoryClass() {
    if (!this.control) return;
    if (hasRequiredValidator(this.control.control)) {
      this.renderer.setAttribute(this.nativeElement, "required", "true");
    } else {
      this.renderer.removeAttribute(this.nativeElement, "required");
    }
  }

  private createErrorElement() {
    const { parentElement, nextSibling } = this.nativeElement;
    const span = this.renderer.createElement("span");
    const idErr = this.control.name + "_" + randomId();
    this.renderer.setAttribute(span, "id", idErr);
    this.renderer.addClass(span, "error-msg");
    this.setErrorText(span);
    this.renderer.insertBefore(parentElement, span, nextSibling);
    this.errorElement = span;
  }

  private updateErrorElement() {
    const span = document.getElementById(this.errorElement.id);
    this.setErrorText(span);
  }

  private removeErrorElement() {
    if (this.errorElement) {
      this.renderer.removeChild(
        this.nativeElement.parentElement,
        this.errorElement
      );
      this.errorElement = null;
    }
  }

  private setErrorText(span) {
    const errMsg = this.errorList()[0];
    this.renderer.setProperty(span, "title", errMsg);
    this.renderer.setProperty(span, "innerHTML", errMsg);
    this.renderer.setValue(span, errMsg);
  }

  private errorList(): string[] {
    const { errors } = this.control;
    return Object.keys(errors).map(field => {
      const label = (this.label && this.label.trim()) || "";
      const errFieldValue = errors[field];
      const msg = FormErrors[field]
        ? FormErrors[field](errFieldValue)
        : FormErrors.invalidValue(errFieldValue);
      return label + " " + msg.trim();
    });
  }

  ngOnDestroy() {
    this.removeErrorElement();
    this.subControl && this.subControl.unsubscribe();
    this.renderer && this.renderer.destroy();
    this.errorElement = null;
  }
}
