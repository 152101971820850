export const FormErrors = {
    // Default
    invalidValue: (_: any) => 'is invalid',

    // Reactive Forms and Bootstrap
    required: () => 'is required',
    bsDate: () => 'is invalid',
    min: ({ min }) => `must be greater than or equal to ${min}`,
    max: ({ max }) => `must be smaller than or equal to ${max}`,
    minlength: ({ actualLength, requiredLength }) => `must be greater than or equal to ${requiredLength}`,
    maxlength: ({ actualLength, requiredLength }) => `must be smaller than or equal to ${requiredLength}`,

    // Custom Validations
    equal: (val: any) => `must be equal to ${val}`,
    equalLength: (val: number) => `length must be ${val}`,
    greaterThan: (val: string) => `must be greater than ${val}`,
    smallerThan: (val: string) => `must be smaller than ${val}`,
    greaterThanOrEqual: (val: string) => `must be greater than or equal to ${val}`,
    smallerThanOrEqual: (val: string) => `must be smaller than or equal to  ${val}`,
};
