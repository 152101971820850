import { Inject, Injectable } from "@angular/core";
import { FSM_API_URL } from "../../../models/environment.token";
import { Store } from "../../../store";
import { HTTPService } from "../../../shared/services/http.service";
import { Pager, PagerQuery } from '../../../models/pager';

import { ApiResponse } from '../../../models/api-response';
import { Observable, throwError } from 'rxjs';
import { catchError, filter, map, retry, tap } from 'rxjs/operators';
import { WorkOrder, WorkOrderDetail, WorkOrderStartPayload,WorkFlowRequestPayload,UpdateConcernPayload } from '../../../models/work-order.types';
import {generateFilterFields,getFormData} from '../../../utils/helpers'

@Injectable({ providedIn: "root" })
export class WorkOrderService {
  constructor(
    private store: Store,
    private http: HTTPService,
    @Inject(FSM_API_URL) private api: string
  ) {

    
  }
  getAllWorkOrders(query: PagerQuery,filters={}, isToBeExported = false) {
    return this.http
      .get<ApiResponse<WorkOrder[]>>(
        this.api + 'work-order/get-list-by-vendor?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          return res.data
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  getWorkOrderDetails(query: PagerQuery,filters={}, isToBeExported = false) {
    return this.http
      .get<ApiResponse<WorkOrderDetail>>(
        this.api + 'work-order/vendor-workflow/'+filters['work_order_id']
      )
      .pipe(
        retry(1),
        tap(res => {
          return res.data
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  startWorkOrder(data: WorkOrderStartPayload): Observable<any> {
    return this.http
      .post<ApiResponse<number[]>>(this.api + 'work-order/start-wo-by-vendor', data)
      .pipe(
        tap(res => {
          if (res.isSuccess) {
            return res.data;
          }
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  saveWorkFlowRemark(data: WorkFlowRequestPayload): Observable<any> {
    return this.http
      .post<ApiResponse<number[]>>(this.api + 'work-order/vendor-workflow-progress/'+data.work_order_id, data)
      .pipe(
        tap(res => {
          if (res.isSuccess) {
            return res.data;
          }
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }

  updateConcern(data: UpdateConcernPayload): Observable<any> {
    return this.http
      .post<ApiResponse<number[]>>(this.api + 'work-order/vendor-add-work-order-conversation/'+data.work_order_id, data)
      .pipe(
        tap(res => {
          if (res.isSuccess) {
            return res.data;
          }
        }),
        catchError(err => {
          return throwError(err);
        })
      );
  }
}
