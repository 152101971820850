import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { HTTPOptions } from '../../models/http/options';
import { LoadingController } from '@ionic/angular';

interface UrlState {
    url: string;
    hideLoader: boolean;
    isFinished?: boolean;
}

@Injectable({ providedIn: 'root' })
export class HTTPService {
    isProcessing = new Subject();
    private urlState: UrlState[] = [];
    loader;

    constructor(
        private http: HttpClient,
        private loadingController: LoadingController
    ) { 
       // this.initilizeLoader();
    }

    async initilizeLoader() {
        this.loader = await this.loadingController.create({
            //cssClass: 'my-custom-class',
            message: 'Please wait...'
          });
    }

    get<T>(url: string, options?: HTTPOptions) {
        this.initRequest(url, options);
        return this.http.get<T>(url, options)
            .pipe(finalize(() => this.finalizeRequest(url)));
    }

    put<T>(url: string, body: any | null, options?: HTTPOptions) {
        this.initRequest(url, options);
        return this.http.put<T>(url, body, options)
            .pipe(finalize(() => this.finalizeRequest(url)));
    }

    post<T>(url: string, body: any | null, options?: HTTPOptions) {
        this.initRequest(url, options);
        return this.http.post<T>(url, body, options)
            .pipe(finalize(() => this.finalizeRequest(url)));
    }

    patch<T>(url: string, body: any | null, options?: HTTPOptions) {
        this.initRequest(url, options);
        return this.http.patch<T>(url, body, options)
            .pipe(finalize(() => this.finalizeRequest(url)));
    }

    private async initRequest(url: string, options: HTTPOptions) {
        await this.initilizeLoader();
        this.isProcessing.next(true);
        this.urlState.push({
            url, isFinished: false,
            hideLoader: Boolean(options && options.hideLoader)
        });
       if (this.urlState.some(x => !x.hideLoader))
        this.loader.present(); // TODO need to check later
    }

    private finalizeRequest(url: string) {
        this.isProcessing.next(false);
        const found = this.urlState.find(x => x.url === url && !x.isFinished);
        if (found) {
            found.hideLoader = false;
            found.isFinished = true;
        }
        if (this.urlState.every(x => x.isFinished)) {
           this.loader.dismiss();
            this.urlState = [];
        }
    }
}
