import { Inject, Injectable, ɵConsole } from "@angular/core";
import { throwError } from "rxjs";
import { catchError, tap } from "rxjs/operators";
import { ApiResponse } from "../../../models/api-response";
import { API_URL } from "../../../models/environment.token";
import { Store } from "../../../store";
import { HTTPService } from "./../../../shared/services/http.service";
import { AuthService } from "./../../../auth/services/auth.service";
import { Pager,PagerQuery } from '../../../models/pager';
import { AuthorityContract } from '../../../models/authority-contract';
import { InventoryType } from '../../../models/inventory-type';
import { Market } from '../../../models/market';
import { InventoryDamageType } from '../../../models/inventory-damage-type';
import { retry } from 'rxjs/operators';
import {generateFilterFields} from '../../../utils/helpers'

@Injectable({ providedIn: "root" })
export class CommonService {
  constructor(
    private store: Store,
    private http: HTTPService,
    private authService: AuthService,
    @Inject(API_URL) private api: string
  ) {}

  getAllContracts(query: PagerQuery,filters={},  isToBeExported = false) {
    return this.http
      .get<ApiResponse<AuthorityContract[]>>(
        this.api + 'contracts-api/contracts?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          !isToBeExported && this.store.set('authorityContractData', res.data || []);
        }),
        catchError(err => {
          !isToBeExported && this.store.set('authorityContractData', null);
          return throwError(err);
        })
      );
  }

  getAllMarkets(query: PagerQuery,isToBeExported = false) {
    
    return this.http
      .get<ApiResponse<Market[]>>(
        this.api + 'market-api/market'
      )
      .pipe(
        retry(1),
        tap(res => {
          !isToBeExported && this.store.set('marketData', res.data || []);
        }),
        catchError(err => {
          !isToBeExported && this.store.set('marketData', null);
          return throwError(err);
        })
      );
  }

  getAllInventoryDamageTypes(query: PagerQuery,isToBeExported = false) {
    
    return this.http
      .get<ApiResponse<InventoryDamageType[]>>(
        this.api + 'inventory-damage-type-api/inventory-damage-type'
      )
      .pipe(
        retry(1),
        tap(res => {
          !isToBeExported && this.store.set('inventoryDamageTypeData', res.data || []);
        }),
        catchError(err => {
          !isToBeExported && this.store.set('inventoryDamageTypeData', null);
          return throwError(err);
        })
      );
  }

  getAllAssetTypeByContract(query: PagerQuery,filters={}, isToBeExported = false) {
    return this.http
      .get<ApiResponse<InventoryType[]>>(
        this.api + 'inventory-type-api/inventory-type?'+generateFilterFields(filters)
      )
      .pipe(
        retry(1),
        tap(res => {
          !isToBeExported && this.store.set('inventoryTypeData', res.data || []);
        }),
        catchError(err => {
          !isToBeExported && this.store.set('inventoryTypeData', null);
          return throwError(err);
        })
      );
  }
}
