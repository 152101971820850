import { AbstractControl, Validators } from '@angular/forms';

export class FormValidators extends Validators {

  static notValidFor(...values: any[]) {
    return (source: AbstractControl) => {
      if (source && values.includes(source.value))
        return { invalidValue: true };
    };
  }

  static validFor(...values: any[]) {
    return (source: AbstractControl) => {
      if (source && !values.includes(source.value))
        return { invalidValue: true };
    };
  }

  static noSpace(AC: AbstractControl) {
    const value = AC.value; // to get value in input tag
    return (value && (value.indexOf(' ') >= 0)) ? ({ invalid: true }) : null;
  }

  static numbersOnly(includeDecimals = false, includeNegativeNumbers = false) {
    return (source: AbstractControl) => {
      if (source && source.value) {
        const reg = includeDecimals && includeNegativeNumbers ? /^(\\?!-0(\.0+)?$)-?(0|[1-9]\d*)(\.\d+)?$/ :
          includeDecimals && !includeNegativeNumbers ? /^(0|[1-9]\d*)(\.\d+)?$/ :
            !includeDecimals && includeNegativeNumbers ? /^-?(0|[1-9]\d*)(\\?<!-0)$/ :
              /^(0|[1-9]\d*)$/;
        if (!reg.test(source.value)) {
          return { invalidValue: true };
        }
      }
    };
  }

  static equalTo(val: string | number) {
    return (source: AbstractControl) => {
      if (source && source.value !== val)
        return { invalidValue: true };
    };
  }

  static equalLength(val: number) {
    return (source: AbstractControl) => {
      if (source && source.value && String(source.value).split('').length !== val)
        return { equalLength: val };
    };
  }

  static required(source: AbstractControl) {
    if (source && (!source.value || String(source.value).trim() === '')) {
      return { required: true };
    }
  }

  static compareDates(targetControl: {
    name: string,
    label?: string,
    comparer: 'equal' | 'greater' | 'smaller' | 'greaterThanOrEqual' | 'smallerThanOrEqual'
  }) {
    return (source: AbstractControl) => {
      if (source && source.parent && source.value) {
        const { name, label, comparer } = targetControl;
        const target = source.parent.controls[name];
        if (target && target.value) {
          const d1 = source.value;
          const d2 = target.value;

          switch (comparer) {
            case 'equal':
              if (d1 !== d2)
                return { equal: label };
              break;
            case 'greater':
              if (d1 <= d2)
                return { greaterThan: label };
              break;
            case 'smaller':
              if (d1 >= d2)
                return { smallerThan: label };
              break;
            case 'greaterThanOrEqual':
              if (d1 < d2)
                return { greaterThanOrEqual: label };
              break;
            case 'smallerThanOrEqual':
              if (d1 > d2)
                return { smallerThanOrEqual: label };
              break;
            default:
              break;
          }
        }
      }
    };
  }

  // Period Format Validations
  static periodFormat(AC: AbstractControl) {
    const value = AC.value; // to get value in input tag
    const pattern = /^(JAN|FEB|MAR|APR|MAY|JUN|JUL|AUG|SEP|OCT|NOV|DEC)-(\d{4})$/;
    return (value && !value.match(pattern)) ? ({ invalid: true }) : null;
  }
}
