import { Component, OnInit } from '@angular/core';
import { Store } from 'src/app/store';
import { User } from 'src/app/models/user';
import { OrderService } from '../../services/orders.service';


@Component({
  selector: 'app-submit-confirmation',
  templateUrl: './submit-confirmation.component.html',
  styleUrls: ['./submit-confirmation.component.scss'],
})
export class SubmitConfirmationComponent implements OnInit {

  constructor(private store: Store,
    private orderService: OrderService
    ) { }

  loggedInUserData = null;
  confirmedSubmissionData;
  submittedOrderID;
  submittedData;
  orderInstallRemoveID;
  submittedOrderInventoryGroup;
 
 

  getSubmissionDetails(){
    this.orderService.getSubmitDetails({},{order_id: this.submittedOrderID,inventory_group: this.submittedOrderInventoryGroup,order_install_remove_id: this.orderInstallRemoveID},this.submittedOrderID).subscribe(({data}) => {
      this.confirmedSubmissionData = data;
    })
  }

  ngOnInit() {}

  ionViewWillEnter() {
    this.store.set('screenTitle',"Confirmation");
    this.store.select('userInfo').subscribe((userData:User) => {
      this.loggedInUserData = userData;
    })
    this.store.select('submittedOrderDetails').subscribe((submittedOrderDetails) => {
      this.submittedData = submittedOrderDetails;
      console.log(this.submittedData);
      this.submittedOrderID = this.submittedData.order_id;
      this.submittedOrderInventoryGroup = this.submittedData.inventory_group;
    })
    this.store.select('orderInstallRemoveID').subscribe((id) => {
     this.orderInstallRemoveID = id;
    })
    this.getSubmissionDetails();
  }

}
