import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../app/services/user.service';
import { ApiResponse } from '../../../../models/api-response';
import { ToastrService } from '../../../../shared/services/toastr.service'
import { Store } from 'src/app/store';


@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  userId = '';
  constructor(
    private router: Router,
    private userService: UserService,
    public toastService: ToastrService,
    private store: Store,
  ) { }

  onSubmit() {
    this.userService.forgotPassword({user_name:this.userId}).subscribe(res => this.handleAlerts(res));
  }

  onUserNameChange = (e)  => {
    this.userId = e.currentTarget.value;
  }


  async handleAlerts(res: ApiResponse<any>) {
    if (res.isSuccess) {
      this.store.set('successMsg', res.message);
      this.toastService.success(res.message);
      setTimeout(()=>{
        this.router.navigate(['login']); 
      },2000)
    } else {
      this.toastService.error(res.errors);
    }
  }

  ngOnInit() {
    this.store.set('screenTitle', "Password Reset");
  }

}
