import { API_URL, FSM_API_URL } from "./api-url";

export const environment = {
  pwa: true,
  production: false,
  api_url: API_URL(true),
  fsm_api_url: FSM_API_URL(true),
  fsm_basic_auth: {
    user: 'gus_api_user',
    password: 'e28471f2d474743982ae04655e1062d0'
  },
  mock: {
    enable: true,
    mockRoot: "assets/mock-api",
    mockUrls: [
      // Urls inside this are mocked ONLY when "enable" is set to "true"
      //"/login"
    ],
    mustMockUrls: [
      // Urls inside this are ALWAYS mocked irrespective of the "enable" fla
    ]
  }
};
