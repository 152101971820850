import { AbstractControl } from "@angular/forms";
import { filter } from 'rxjs/operators';

export const randomIdSimple = () =>
  Math.random()
    .toString(36)
    .slice(2);
export const randomId = (a = null, b = null) => {
  for (
    b = a = "";
    a++ < 36;
    b +=
    (a * 51) & 52
      ? (a ^ 15 ? 8 ^ (Math.random() * (a ^ 20 ? 16 : 4)) : 4).toString(16)
      : "-"
  );
  return b;
};

export const hasRequiredValidator = (ac: AbstractControl): boolean => {
  if (!ac) return false;
  if (ac.validator) {
    const validator = ac.validator({} as AbstractControl);
    if (validator && validator.required) {
      return true;
    }
  }
  const controls = ac["controls"];
  if (controls) {
    // tslint:disable-next-line:forin
    for (const controlName in controls) {
      const control = controls[controlName];
      if (control && hasRequiredValidator(control)) return true;
    }
  }
  return false;
};

export const generateFilterFields= (filters) => {
  let filterBy='';
  for (const [filterKey, filterValue] of Object.entries(filters)) {
    filterBy +='filterBy[' + filterKey + ']=' + filterValue+'&';
  }
  filterBy = filterBy ? filterBy.substring(0,filterBy.length-1):filterBy;
  return filterBy;
}

export const getFormData= (filters) => {
  const formData = new FormData();
  for (const [filterKey, filterValue] of Object.entries(filters)) {
    formData.append(filterKey, filterValue.toString());
  }
  return formData;
}
