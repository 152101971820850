import {Component, OnInit,ViewChild, ElementRef} from '@angular/core';
import { Store } from 'src/app/store';
import {Router} from '@angular/router';
import { AvailableInventory, DisplayTypeInventory,OrderJobDetail } from 'src/app/models/order-job-detail';
import { OrderService } from '../../services/orders.service';
import { ResponseType } from '../listorder/orders.component';
import { CrewOrder } from 'src/app/models/crew-order';
import { FormBuilder, FormGroup,Validators } from '@angular/forms';
import { FormValidators } from '../../../../utils/form-validators';
import { ToastrService } from 'src/app/shared/services/toastr.service';
import { ApiResponse } from 'src/app/models/api-response';
import { User } from 'src/app/models/user';
import {ActionSheetController, AlertController} from '@ionic/angular'
import { DomSanitizer } from '@angular/platform-browser';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { BarcodeScanner,BarcodeScannerOptions } from '@ionic-native/barcode-scanner/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Platform } from '@ionic/angular';
import Quagga from 'quagga';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'app-install-ad',
  templateUrl: 'install-ad.html',
  styleUrls: ['install-ad.scss']
})
export class InstallAdComponent implements  OnInit{

  @ViewChild('fileInput') fileInput: ElementRef;
  @ViewChild('filePreInput') filePreInput: ElementRef;

  constructor(private store: Store, public router: Router,private orderService: OrderService,
    private fb: FormBuilder,
    public toastService:ToastrService,
    private camera: Camera,
    public domSanitizer: DomSanitizer,
    private barcodeScanner: BarcodeScanner,
    public geolocation: Geolocation,
    private alertCtrl : AlertController,
    public platform: Platform,
    public actionController: ActionSheetController
    ) {
  }

  public prePhotos: string[];
  public postPhotos: string[];
  fileAttr = 'Choose File';
  prefileAttr = 'Choose File';

  navigatorObject = window.navigator;
  booleanNavigatorObject;

  selectedOrderDisplayType : DisplayTypeInventory= null;
  selectedOrderData: CrewOrder= null;
  availableInventories: AvailableInventory[]= [];
  fgInstallAd: FormGroup;
  loggedInUserID:number=null;
  isSubmitDisable: boolean = false;
  isPlatformPWA: boolean = false;
  predataimage: any;
  postdataimage: any;
  isEnvironmentPWA : boolean;
  isDynamicEvenDistribution : boolean = false;
  showCameraForBarcode : boolean = false;
  barcodeInputValue: any;


  checkPlatform(){
    if(this.platform.is('mobileweb') || this.platform.is('pwa')){
      this.isPlatformPWA = true
    }else{
      this.isPlatformPWA = false;
    }
    this.booleanNavigatorObject =  Boolean(this.navigatorObject);
    console.log(environment,environment.pwa);
    this.isEnvironmentPWA = environment.pwa;
    console.log(this.isPlatformPWA,this.platform.platforms(), 'Navigator Value :',this.booleanNavigatorObject)
  }

  get assignedUnitNumbers() {
    if(this.selectedOrderDisplayType) {
      return this.selectedOrderDisplayType.unit_details;
    }
    return [];
  }

  findUserLocation(){ 
    let options = {
      enableHighAccuracy: true,
      timeout: 25000
    };


    return this.geolocation.getCurrentPosition(options).then((position) => {
      this.fgInstallAd.get('latitude').setValue(position.coords.latitude)
      this.fgInstallAd.get('longitude').setValue(position.coords.longitude)
     }).catch((error) => {
       console.log('Error getting location', error);
     });
  }



  getAvailableInventories() {
    if(this.selectedOrderDisplayType && this.selectedOrderData) {
        const filters = {
          orderId: this.selectedOrderData.order_id,
          displayTypeId: this.selectedOrderDisplayType.display_type_id,
          inventoryTypeId: this.selectedOrderDisplayType.inventory_type_id,
          adTypeId:this.selectedOrderDisplayType.ad_type_id,
          contractId: this.selectedOrderData.contract_id,
          zoneId :this.selectedOrderDisplayType.zone_id,
          garageId:this.selectedOrderDisplayType.garage_id,
          postDate:this.selectedOrderData.inv_post_date,
          expiryDate: this.selectedOrderData.inv_expiry_date,
          full_wrap:this.selectedOrderDisplayType.display_type==='Full Wrap'?'Full wrap':'',
          quantity: '', //this.selectedOrderDisplayType.quantity,
          installedQuantity: this.selectedOrderDisplayType.installed_quantity
        }
       this.orderService
          .getAvailableInventories({},filters)
          .subscribe(({ data }) => {
            this.availableInventories = data.data;
          });
    }
  }

  async onSubmit() {
    this.isSubmitDisable= true;
    await this.findUserLocation();
    let data = { ...this.fgInstallAd.getRawValue() };
    if(!data.bar_code && this.barcodeInputValue){
      data.bar_code = this.barcodeInputValue;
    }

    data.image_data = [];
    this.prePhotos.forEach(photo => {
        data.image_data.push({'image_base64':photo,'action_type':'Pre-Install'});
    });

    this.postPhotos.forEach(photo => {
      data.image_data.push({'image_base64':photo,'action_type':'Post-Install'});
    });

    let submissionData = data;
    this.store.set('submittedOrderDetails', submissionData || null);
    
    this.orderService.saveInstallAd(data).subscribe(res => {
      this.isSubmitDisable= false;
      this.handleAlerts(res)
    });
  }

  async handleAlerts(res: ApiResponse<any>) {
    if(res.isSuccess) {
      this.toastService.success('Inventory has been installed successfully');
      this.store.set('orderInstallRemoveID',res.data.order_install_remove_id);
      this.router.navigate(['submit-confirmation']);
    } else {
      this.toastService.error(res.errors);
    }
  }

  // PWA take photos function - Pre Install Photos
  uploadPreFileEvent(preimgFile: any){
    if(preimgFile.target.files && preimgFile.target.files[0]) {
      this.prefileAttr = '';
      Array.from(preimgFile.target.files).forEach((file: File) => {
        this.prefileAttr += file.name ;
      });
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          let imgBase64Path = e.target.result;
          console.log(imgBase64Path);
          this.predataimage = imgBase64Path.replace(/^data:image\/[a-z]+;base64,/,'');
          this.fgInstallAd.get('image_base64').setValue(this.predataimage);
          this.prePhotos.push(this.fgInstallAd.get('image_base64').value);
          this.prePhotos.reverse();
          console.log(this.prePhotos);
        };
      };
      reader.readAsDataURL(preimgFile.target.files[0]);
      
      // Reset if duplicate image uploaded again
      this.filePreInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  async selectPreImage(){
    const actionSheet = await this.actionController.create({
      header: "Select Image Source",
      buttons: [{
        text: 'Upload from Gallery',
        handler: () => {
          this.takePrePictureFromGallery();
        }
      },
      {
          text: 'Use Camera',
          handler: () => {
            this.takePrePicture();
          }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
    ]
    });
    await actionSheet.present();
  }

  // Mobile app use Camera function - Pre Install Photos
  takePrePicture() {
    const options: CameraOptions = {
      targetWidth: 400,
      targetHeight: 600,
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      cameraDirection: this.camera.Direction.BACK
    }
    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      let base64Image = imageData;
      this.fgInstallAd.get('image_base64').setValue(base64Image);
      this.prePhotos.push(this.fgInstallAd.get('image_base64').value);
      this.prePhotos.reverse();

     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
        console.log("Error in taking picture", err);
     });
  }

  // Mobile app upload from gallery function - Pre Install Photos
  takePrePictureFromGallery(){
    this.camera.getPicture({
      quality: 100,
      targetWidth: 900,
      targetHeight: 600,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG

     }).then((imageData) => {
  
      let base64Image = imageData;
      this.fgInstallAd.get('image_base64').setValue(base64Image);
      this.prePhotos.push(this.fgInstallAd.get('image_base64').value);
      this.prePhotos.reverse();  
     // this.submitDisable();
     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
       console.log("Error in taking picture", err);
     });
     
  }

  // PWA take photos function - Post Install Photos 
  uploadPostFileEvent(imgFile: any){
    if(imgFile.target.files && imgFile.target.files[0]) {
      this.fileAttr = '';
      Array.from(imgFile.target.files).forEach((file: File) => {
        this.fileAttr += file.name ;
      });
      let reader = new FileReader();
      reader.onload = (e: any) => {
        let image = new Image();
        image.src = e.target.result;
        image.onload = rs => {
          let imgBase64Path = e.target.result;
          console.log(imgBase64Path);
          this.postdataimage = imgBase64Path.replace(/^data:image\/[a-z]+;base64,/,'');
          this.fgInstallAd.get('image_base64').setValue(this.postdataimage);
          this.postPhotos.push(this.fgInstallAd.get('image_base64').value);
          this.postPhotos.reverse();
          console.log(this.postPhotos)
        };
      };
      reader.readAsDataURL(imgFile.target.files[0]);
      
      // Reset if duplicate image uploaded again
      this.fileInput.nativeElement.value = "";
    } else {
      this.fileAttr = 'Choose File';
    }
  }

  async selectAfterImage(){
    const actionSheet = await this.actionController.create({
      header: "Select Image Source",
      buttons: [{
        text: 'Upload from Gallery',
        handler: () => {
          this.takePostPictureFromGallery();
        }
      },
      {
          text: 'Use Camera',
          handler: () => {
            this.takePostPicture();
          }
      },
      {
        text: 'Cancel',
        role: 'cancel'
      }
    ]
    });
    await actionSheet.present();
  }


  // Mobile app upload from gallery function - Post Install Photos
  takePostPicture() {
    const options: CameraOptions = {
      targetWidth: 400,
      targetHeight: 600,
      quality: 100,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG,
      cameraDirection: this.camera.Direction.BACK
    }
    this.camera.getPicture(options).then((imageData) => {
      // imageData is either a base64 encoded string or a file URI
      // If it's base64 (DATA_URL):
      let base64Image = imageData;
      this.fgInstallAd.get('image_base64').setValue(base64Image);
      this.postPhotos.push(this.fgInstallAd.get('image_base64').value);
      this.postPhotos.reverse();

     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
        console.log("Error in taking picture", err);
     });
  }

  takePostPictureFromGallery(){
    this.camera.getPicture({
      quality: 100,
      targetWidth: 900,
      targetHeight: 600,
      sourceType: this.camera.PictureSourceType.PHOTOLIBRARY,
      destinationType: this.camera.DestinationType.DATA_URL,
      encodingType: this.camera.EncodingType.JPEG
     }).then((imageData) => {
      let base64Image = imageData;
      this.fgInstallAd.get('image_base64').setValue(base64Image);
      this.postPhotos.push(this.fgInstallAd.get('image_base64').value);
      this.postPhotos.reverse();  
     }, (err) => {
       this.toastService.error("Error in taking picture "+err)
       console.log("Error in taking picture", err);
     });
     console.log(this.postPhotos);   
  }

  async deletePrePhoto(index) {
    let confirm = await this.alertCtrl.create({
      message: 'Are you sure you want to delete this photo?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            console.log('Disagree clicked');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Agree clicked');
            this.prePhotos.splice(index, 1);
          }
        }
      ]
    });
    await confirm.present();   
   }

   async deletePostPhoto(index) {
    let confirm = await this.alertCtrl.create({
      message: 'Are you sure you want to delete this photo?',
      buttons: [
        {
          text: 'No',
          handler: () => {
            console.log('Disagree clicked');
          }
        }, {
          text: 'Yes',
          handler: () => {
            console.log('Agree clicked');
            this.postPhotos.splice(index, 1);
          }
        }
      ]
    });
    await confirm.present();   
   }

  scanBarCode() {
    const options: BarcodeScannerOptions = {
      torchOn: true,
      showTorchButton: true
    }
   this.barcodeScanner.scan(options).then(barcodeData => {
      this.fgInstallAd.get('bar_code').setValue(barcodeData.text);
     }).catch(err => {
         console.log('Error in bar code scan', err);
     });
  }

  scan(){
    let canvas = document.getElementById('canvasArea');
    canvas.style.display = "none";
 
    this.showCameraForBarcode = true; 
     Quagga.init({
       inputStream: {
         constraints: {
           facingMode: 'environment' // restrict camera type
         },
         area: { // defines rectangle of the detection
           top: '40%',   // top offset
           right: '0%',  // right offset
           left: '0%',   // left offset
           bottom: '40%' // bottom offset
         },
       },
     },
     (err) => {
       if (err) {
        console.log('Error in bar code scan', err)
       } else {
         Quagga.start();
         Quagga.onDetected((res) => {
           this.fgInstallAd.get('bar_code').setValue(res.codeResult.code);
          // window.alert(`code: ${res.codeResult.code}`);
          Quagga.stop();
          this.showCameraForBarcode = false;
         })
       }
     });
   } 

  ngOnInit() {
    this.checkPlatform();
    this.prePhotos = [];
    this.postPhotos = [];

    this.store.set('screenTitle', "Installation");

    const { required } = FormValidators;

    this.store.select('selectedOrderData').subscribe((selectedOrderData:ResponseType) => {
      this.selectedOrderData = selectedOrderData.selectedOrder;
    })

    this.store.select('selectedOrderDisplayType').subscribe((selectedOrderDisplayType:DisplayTypeInventory) => {
      this.selectedOrderDisplayType = selectedOrderDisplayType;
     })

     this.store.select('userInfo').subscribe((userData:User) => {
      this.loggedInUserID = userData.user_id;
     })

     if(this.selectedOrderDisplayType.inventory_group == 'Dynamic' && this.selectedOrderDisplayType.zone_id == null &&  this.selectedOrderDisplayType.garage_id == null){
       this.isDynamicEvenDistribution = true;
     }else{
      this.isDynamicEvenDistribution = false;
     }

     console.log(this.isDynamicEvenDistribution);

     this.fgInstallAd = this.fb.group({
      order_id: [this.selectedOrderData?this.selectedOrderData.order_id:'', [required]],
      inventory_group: [this.selectedOrderDisplayType?this.selectedOrderDisplayType.inventory_group:'', [required]],
      post_date: [this.selectedOrderData?this.selectedOrderData.inv_post_date:'', [required]],
      expiry_date: [this.selectedOrderData?this.selectedOrderData.inv_expiry_date:'',[required]],
      display_type: [this.selectedOrderDisplayType?this.selectedOrderDisplayType.display_type:'',[required]],
      assigned_inventory : [null,[required]],
      changed_inventory : [null],     // TODO Conditional validatior
      user_id : [this.loggedInUserID],
      quantity : [this.selectedOrderDisplayType?this.selectedOrderDisplayType.quantity:''],
      bar_code : [''],
      unit_quantity: [1,[Validators.max(26), Validators.min(1),required]],
      ad_type_id: [this.selectedOrderDisplayType?this.selectedOrderDisplayType.ad_type_id:'',[required]],
      garage_id: [this.selectedOrderDisplayType?this.selectedOrderDisplayType.garage_id:''],
      zone_id: [this.selectedOrderDisplayType?this.selectedOrderDisplayType.zone_id:''],
      image_base64 : [''],
      is_change_inventory: [false],
      latitude: [''],
      longitude: ['']
    });
     this.getAvailableInventories();
  }
}